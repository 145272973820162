import React from "react";
import onClickOutside from "react-onclickoutside"
import General from "../../../utils/General";

class JobDatePostedFilter extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      datesPosted: [{name: 'All'}, {name: '24_hours'}, {name: '7_days'}, {name: '28_days'}],
      showFilters: false
    }
  }

  handleClickOutside = evt => {
    let {
      showFilters
    } = this.state

    if(showFilters){
      this.setState({showFilters: false})
    }
  };

  componentDidMount() {
    this._formatting()
  }

  // componentWillReceiveProps(nextProps, nextContext) {
  //   this.setState(nextProps, () => this._formatting())
  // }

  _formatting(){
    let {
      datesPosted
    } = this.state

    let formattedContracts = datesPosted.map(datePosted => {
      if(datePosted.name === "All"){
        return {
          name: datePosted.name,
          isChecked: datePosted.hasOwnProperty('isChecked') ? datePosted.isChecked : true
        }
      }else{
        return {
          name: datePosted.name,
          isChecked: datePosted.hasOwnProperty('isChecked') ? datePosted.isChecked : false
        }
      }
    })

    this.setState({datesPosted: formattedContracts})
  }

  _handleChange(datePosted){
    let {
      datesPosted
    } = this.state

    let id = datesPosted.findIndex((date) => {
      return date.name === datePosted.name
    })

    datesPosted.map(contract => {
      contract.isChecked = false;
    })

    if(datePosted.name !== 'All'){
      datesPosted[id].isChecked = true;
    }else{
      datesPosted[0].isChecked = true;
    }
    this.setState({datesPosted})

    let selected = this._getSelectedDate(datesPosted)

    this.props.setFilter(selected)
  }

  _getSelectedDate(dates){
    let selectedDate = []
    dates.map(filter => {
      if(filter.isChecked){
        selectedDate.push(filter.name)
      }
    })
    return selectedDate
  }

  _renderDates(){
    let { datesPosted } = this.state

    return datesPosted.map(datePosted => {
      return (
        <div className="input-radio col-12">
          <input
            value={datePosted.name}
            type="radio"
            id={`filter-posted-${datePosted.name}`}
            name={"filter-posted"}
            checked={datePosted.isChecked}
            onChange={() => {
              this._handleChange(datePosted)
            }}
          />
          <label className="mt-0" htmlFor={`filter-posted-${datePosted.name}`}/>
          <span>{General.snakeCaseToTitleCase(datePosted.name)}</span>
        </div>
      )
    })
  }

  render() {
    let {
      showFilters
    } = this.state

    return (
      <>
        <div className={"col-auto"}>
          <button
            style={{padding: '0 25px', borderRadius: 50, backgroundColor: '#f1f1f1'}}
            className={"c-btn btn type--uppercase"}
            onClick={e => {
              e.preventDefault()
              this.setState({showFilters: !showFilters})
            }}
          >
            Date Posted
          </button>
          {
            showFilters &&
            <div
              className="feature feature-1 boxed boxed--border"
              style={{position: 'absolute', zIndex: 99, marginTop: 10}}
            >
              <div className="radio-wrapper p-0">
                { this._renderDates() }
              </div>
            </div>
          }
        </div>
      </>
    )
  }
}

export default onClickOutside(JobDatePostedFilter)