import React from "react";
import renderHTML from 'react-render-html';
import General from "../../../utils/General";
import Indeed from "../../../utils/Indeed";
import Backend from "../../../utils/Backend";

export default class Description extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      job: props.job,
      partner: props.partner,
      variation: props.variation
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  render() {
    let {
      job,
      partner,
      variation
    } = this.state

    let parentClassName = "col-lg-6 middlecol col-lg-push-3"
    if(variation==="v2"){
      parentClassName = "col-lg-9 middlecol"
    }

    let showSkills = false;
    if ( (job.skills).length > 0 ){
      showSkills = true;
    }

    let applyUrl = `https://jobbio.com/talent/register?app_source=${partner.slug}&job=${job.id}&source=${partner.slug}`;

    if (job.redirect) {
      applyUrl = Indeed.getIndeedTrackingLink(job.redirect, partner.slug);
    }

    return (
      <>
        <div className={parentClassName}>

          { variation!="v2" && 
            <h4>Job Details</h4>
          }
          <div className="section">
            <div className="job-cards" id="job-details">

              <div className="row boxed boxed--border post post-text mt-0">
                <p>{renderHTML(job.description)}</p>

                <div className="buttons w-100 text-center mt-26px">
                    <a
                      href={applyUrl}
                      target={"_blank"}
                      className="btn btn--sm btn--primary type--uppercase"
                      onClick={() => {
                        Backend.applyClick('job_description', partner)
                        if(job.redirect){
                          Backend.jobRedirect(job, partner, applyUrl)
                        }
                      }}
                    >
                      <span className="btn__text">Apply</span>
                    </a>
                  </div>

              </div>

              { showSkills &&
                <div className="row boxed boxed--border post skills">
                  <div className="col-12">
                    <h4>Skills</h4>
                    <div>
                      {(job.skills).map(object => {
                        return <span>{object.name}</span>
                      })}
                    </div>
                  </div>
                </div>
              }


            </div>
          </div>
        </div>
      </>
    )
  }
}
