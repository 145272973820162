import React from 'react'

import Creatable from "react-select/creatable";
import {AsyncPaginate} from 'react-select-async-paginate';

import FetchHelper from '../../../utils/FetchHelper'

export default class AsyncSelect extends React.Component  {
  constructor(props){
    super(props)

    this.state = {
      ...this._getState(props),
      search: '',
      prevSearch: '',
      items: [],
      nextPageUrl: null,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      ...props,
      placeholder: props.placeholder,
      endpoint: props.endpoint,
      isMulti: props.isMulti ? props.isMulti : false,
      creatable: props.creatable ? props.creatable : false,
      styles: props.styles,
      filter: props.filter || '',
      value: props.value,
      disabled: props.disabled
    }
  }

  _loadOptions(search, prevOptions){
    let {
      endpoint,
      prevSearch,
      nextPageUrl,
      filter,
      items
    } = this.state

    if(!search){
      return
    }

    let url = `${endpoint}?${filter ? filter + '&' : ''}search=${search}&order_by=${this.props.orderBy}`
    let nextPage = false
    if(search === prevSearch && nextPageUrl){
      url = nextPageUrl
      nextPage = true
    }

    return FetchHelper.get(url)
    .then(response => {
      let newOptions = this.props.getOptions(response.results)
      if(nextPage){
        items = [
          ...items,
          ...response.results
        ]
      }
      else{
        items = response.results
      }

      this.setState({
        items,
        nextPageUrl: response.next,
        prevSearch: search
      })
      return {
        options: newOptions,
        hasMore: response.next != null
      }
    })
  };

  _handleChange(option){
    if(this.state.isMulti){
      this.setState({ value: option }, () =>{
        this.props.onSelected(this.state.value)
      })
    }else if(option?.__isNew__){
      this.setState({ value: option }, () => {
        this.props.onCreated(option)
      })
    } else{
      this.setState({ value: option }, () => {
        this.props.onSelected(option?.data)
      })
    }
  }

  render(){
    let props = {}

    if(this.state.creatable){
      props.SelectComponent = Creatable
    }
    return (
      <AsyncPaginate
        { ...props }
        className={`react-select-async-paginate ${this.props.plainClassName}`}
        key={this.state.endpoint}
        isMulti={this.props.isMulti}
        isClearable={this.props.isClearable}
        classNamePrefix={this.props.classNamePrefix}
        value={this.state.value}
        styles={this.state.styles}
        closeMenuOnSelect={!this.state.isMulti}
        placeholder={this.props.placeholder}
        loadOptionsOnMenuOpen={false}
        loadOptions={this._loadOptions.bind(this)}
        debounceTimeout={500}
        formatCreateLabel={inputValue => {
          if(!inputValue){
            return <b>+ Create</b>
          }
          return <b>{`+ Create "${inputValue}"`}</b>
        }}
        isValidNewOption={({inputValue, selectValue, selectOptions}) => {
          return true
        }}
        onChange={value => {
          this._handleChange(value)
        }}
        isDisabled={this.state.disabled}
      />
    )
  }
}

AsyncSelect.defaultProps = {
  isMulti: false,
  orderBy: 'name',
  plainClassName: '',
  classNamePrefix: '',
  isClearable: false,
  placeholder: 'Select...'
}
