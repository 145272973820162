import React, { Component } from 'react';

import LazyLoader from './LazyLoader'
import List from './List'
import AuthManager from '../../../utils/AuthManager'


export default class LazyLoadingList extends Component  {

  constructor(props){
    super(props);
    this.state = {
      endpoint: props.endpoint,
      items: [],
      isInitialLoading: true,
    }
    this.lazyLoader = React.createRef()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  refresh(){
    this.lazyLoader.current._refresh()
  }

  _renderList(){

    let {
      items,
      isInitialLoading
    } = this.state

    if(isInitialLoading){
      return this.props.renderInitialLoading()
    }

    return (
      <List
        items={items}
        renderItem={(item) => this.props.renderItem(item)}
        loadMore={() => {
          if(this.lazyLoader.current){
            if(!this.props.loadOnce){
              this.lazyLoader.current._loadMore()
            }
          }
        }}
      />
    )
  }

  render(){
    let {
      endpoint,
      items,
      isInitialLoading
    } = this.state

    return (
      <LazyLoader
        ref={this.lazyLoader}
        endpoint={endpoint}
        headers={AuthManager.getHeaders()}
        onEndReached="loadMore"
        onItemsUpdated={items => {
          this.props.onItemUpdated(items)
          this.setState({items, isInitialLoading: false})
        }}
        onRefresh={items => (
          this.setState({items:[], isInitialLoading: true})
        )}
      >
        { this._renderList() }
      </LazyLoader>
    )
  }


}

LazyLoadingList.defaultProps = {
  loadOnce: false,
  renderInitialLoading: () => <div/>,
  onItemUpdated: () => null
}