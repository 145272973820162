const SMALL = "small"
const MEDIUM = "medium"
const LARGE = "large"

export default class Image {
  static getImageUrl(image, block, position){
    return image?.replace("/fit-in/", `/fit-in/${Image.getResolution(block, position)}/`)
  }

  static getResolution(block, position) {
    let windowWidth = window.innerWidth

    if (windowWidth < 768) {
      switch (block) {
        case 2:
          return "750x728";
        case 8:
          return position === "background" ? "750x1164" : "690x456";
        case 9:
          return "448x380";
        case 10:
          return "750x824";
        case 15:
          return "750x476";
        case 16:
          return "690x456";
        case "banner":
          return "750x892";
        case "primary-featured-company":
          return "690x756";
        case "company-card":
          return "690x388";
        case "job-logo":
          return "104x104";
        case "other-options":
          return "80x80";
        case "company-logo":
          return "200x200";
        case "channel-card":
          return "690x332";
        default:
          return "750x892";
      }
    }

    if (windowWidth < 1280) {
      switch (block) {
        case 2:
          return "1536x1432";
        case 8:
          return position === "background" ? "1536x1640" : "420x716";
        case 9:
          return "420x356";
        case 10:
          return "768x1024";
        case 15:
          return "512x792";
        case 16:
          return "660x716";
        case "banner":
          return "1536x1230";
        case "primary-featured-company":
          return "750x892";
        case "company-card":
          return "420x236";
        case "job-logo":
          return "104x104";
        case "other-options":
          return "80x80";
        case "company-logo":
          return "200x200";
        case "channel-card":
          return "420x332";
        default:
          return "1380x1024";
      }
    }

    switch (block) {
      case 2:
        return "2560x1120";
      case 8:
        return position === "background" ? "2560x1280" : "1080x716";
      case 9:
        return "492x416";
      case 10:
        return "844x800";
      case 15:
        return "1264x848";
      case 16:
        return "1080x716";
      case "banner":
        return "2560x960";
      case "primary-featured-company":
        return "2220x800";
      case "company-card":
        return "700x392";
      case "job-logo":
        return "104x104";
      case "other-options":
        return "80x80";
      case "company-logo":
        return "200x200";
      case "channel-card":
        return "700x364";
      default:
        return "2560x960";
    }
  }
}