import React from "react";
import $ from "jquery";
import Image from "../../../../utils/Image";

export default class Block8 extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      contentBlock: props.contentBlock
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderBlockStyle(){
    let {
      contentBlock
    } = this.state

    return (
      <style
        dangerouslySetInnerHTML={{__html: `
          #section-${contentBlock.id} .customPrimaryButtonLargeVideo {
            background-color: ${contentBlock.primary_color} !important;
            border-color: ${contentBlock.primary_color} !important;
          }
        `}}
      />
    )
  }

  _handleClick(link){
    if(link.includes('section-')){
      $('html, body').stop().animate({
        scrollTop: $(`${link}`).offset().top-100,
      }, 800, 'swing');
    }else{
      window.open(link)
    }
  }

  render() {
    let {
      contentBlock
    } = this.state

    let primaryCTALink = contentBlock.primary_cta_link.includes('section-') ? `#${contentBlock.primary_cta_link}` : `${contentBlock.primary_cta_link}`
    let cloudFrontLinkBG = contentBlock.background_image.includes(".gif") ? window.General.CloudFront : window.General.CloudFrontResizer
    let cloudFrontLinkFG = contentBlock.foreground_image.includes(".gif") ? window.General.CloudFront : window.General.CloudFrontResizer

    return (
      <>
        {this._renderBlockStyle()}
        <section
          className="block-08 cover height-80 imagebg switchable switchable--switch"
          id={`section-${contentBlock.id}`}
          data-overlay={contentBlock.overlay}
          style={{
            backgroundColor: contentBlock.background_color,
            padding: `${contentBlock.padding_top}px ${contentBlock.padding_right}px ${contentBlock.padding_bottom}px ${contentBlock.padding_left}px`,
            margin: `${contentBlock.margin_top}px ${contentBlock.margin_right}px ${contentBlock.margin_bottom}px ${contentBlock.margin_left}px`
          }}
        >
          <div className="background-image-holder">
            <img alt="background" src={Image.getImageUrl(`${cloudFrontLinkBG}images2/topic/${contentBlock.background_image}`, 8, "background")}/>
          </div>
          <div className="container pos-vertical-center">
            <div className="row justify-content-between align-items-center">
              <div className="col-xl-5 col-lg-7">
                <div className="switchable__text">
                  <h1 style={{color: contentBlock.text_color}}>{contentBlock.title}</h1>
                  <p className="lead" style={{color: contentBlock.text_color}}>{contentBlock.text}</p>
                  <a
                    className="btn btn--primary type--uppercase customPrimaryButtonLargeVideo"
                    href={primaryCTALink}
                    onClick={e => {
                      e.preventDefault()
                      this._handleClick(primaryCTALink)
                    }}
                  >
                    <span className="btn__text">{contentBlock.primary_cta_text}</span>
                  </a>
                </div>
              </div>
              <div className="col-xl-6 col-lg-4 col-12">
                <div className="video-cover border--round box-shadow-wide">
                  <div className="background-image-holder">
                    <img alt="image" src={Image.getImageUrl(`${cloudFrontLinkFG}images2/topic/${contentBlock.foreground_image}`, 8, "foreground")}/>
                  </div>
                  <div className="video-play-icon"/>
                  <iframe
                    data-src={contentBlock.video}
                    allowFullScreen="allowfullscreen"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}