import React from "react";

export default class JobShimmerDescription extends React.Component{
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  render() {
    let {

    } = this.state

    return (
      <>
        <div className="col-lg-12 search-job-detail">
          <div className="">
            <div className="job-detail-logo shine">
              <div className="initials shine" style={{border: 'none'}}></div>
            </div>
            <div className="details-top text-center" style={{padding: '0px 0px 30px'}}>
              <div style={{width: '10%',height: '55px',marginLeft: '0%', display: 'inline-block'}} className="line shine"></div>
              <div style={{display: 'inline-block', width: '80%'}}>
                <div style={{width: '30%',height: '10px',marginLeft: '10px'}} className="line shine"></div>
                <div style={{width: '30%',height: '10px',marginLeft: '10px'}} className="line shine"></div>
                <div style={{width: '30%',height: '10px',marginLeft: '10px'}} className="line shine"></div>
              </div>
            </div>
            <div className="details-content boxed">
              <div style={{marginTop: '0'}} className="row boxed--border post post-text">
                <div style={{width: '40%',height: '25px'}} className="line shine"></div>
                <div style={{width: '90%',marginTop: '30px'}} className="line shine"></div>
                <div style={{width: '98%'}} className="line shine"></div>
                <div style={{width: '98%'}} className="line shine"></div>
                <div style={{width: '97%'}} className="line shine"></div>
                <div style={{width: '98%'}} className="line shine"></div>
                <div style={{width: '98%'}} className="line shine"></div>
                <div style={{width: '90%'}} className="line shine"></div>
                <div style={{width: '68%'}} className="line shine"></div>

                <div style={{width: '60%',marginTop: '30px',height: '25px'}} className="line shine"></div>
                <div style={{width: '95%',marginTop: '30px'}} className="line shine"></div>
                <div style={{width: '99%'}} className="line shine"></div>
                <div style={{width: '99%'}} className="line shine"></div>
                <div style={{width: '96%'}} className="line shine"></div>
                <div style={{width: '99%'}} className="line shine"></div>
                <div style={{width: '95%'}} className="line shine"></div>
                <div style={{width: '90%'}} className="line shine"></div>
                <div style={{width: '68%'}} className="line shine"></div>
              </div>
            </div>
          </div>
        </div>

      </>
    )
  }
}