import React from "react";

export default class Block1 extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      contentBlock: props.contentBlock
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  render() {
    let {
      contentBlock
    } = this.state

    return (
      <>
        <section
          className="block-01 text-center"
          id={`section-${contentBlock.id}`}
          style={{
            backgroundColor: contentBlock.background_color,
            padding: `${contentBlock.padding_top}px ${contentBlock.padding_right}px ${contentBlock.padding_bottom}px ${contentBlock.padding_left}px`,
            margin: `${contentBlock.margin_top}px ${contentBlock.margin_right}px ${contentBlock.margin_bottom}px ${contentBlock.margin_left}px`
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-xl-12">
                <h1 style={{color: contentBlock.text_color}}>{contentBlock.title}</h1>
                <p className="lead" style={{color: contentBlock.tertiary_color}}>{contentBlock.subtitle}</p>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}