import React from "react";
import Image from "../../../../utils/Image";

export default class Block9 extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      contentBlock: props.contentBlock
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderBlockStyle(){

  }

  _renderContent(){
    let {
      contentBlock
    } = this.state

    return contentBlock.block_content_entry.map(content => {
      let cloudFrontLink = content.image.includes(".gif") ? window.General.CloudFront : window.General.CloudFrontResizer
      return (
        <div className="col-lg-4">
          <div className="feature feature-8">
            <img alt="Image" src={Image.getImageUrl(`${cloudFrontLink}images2/topic/${content.image}`, 9)}/>
            <h5 style={{color: contentBlock.text_color}}>{content.title}</h5>
            <span style={{color: contentBlock.tertiary_color}}>{content.subtitle}</span>
          </div>
        </div>
      )
    })
  }

  render() {
    let {
      contentBlock
    } = this.state

    return (
      <>
        <section
          className="block-09 text-center"
          id={`section-${contentBlock.id}`}
          style={{
            backgroundColor: contentBlock.background_color,
            padding: `${contentBlock.padding_top}px ${contentBlock.padding_right}px ${contentBlock.padding_bottom}px ${contentBlock.padding_left}px`,
            margin: `${contentBlock.margin_top}px ${contentBlock.margin_right}px ${contentBlock.margin_bottom}px ${contentBlock.margin_left}px`
          }}
        >
          <div className="container">
            <div className="row justify-content-around">
              {this._renderContent()}
            </div>
          </div>
        </section>
      </>
    )
  }
}