import React from "react";

import CompanyItem from "./CompanyItem";
import LazyLoadingList from "../common/LazyLoadingList";
import CompanyShimmerItem from "./CompanyShimmerItem";
import General from "../../../utils/General";
import ArticleItem from "./ArticleItem";
import ScriptCache from "../../../utils/ScriptCache";

export default class ArticleResults extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      loading: true,
      searchTerm: props.searchTerm,
      countryId: props.countryId,
      stateId: props.stateId,
      partner: props.partner,
      selectedCategory: props.selectedCategory
    }
  }

  _loadJs(){
    ScriptCache.loadDefaults()
  }

  _renderArticles(){
    let {
      partner,
      searchTerm,
      selectedCategory
    } = this.state

    let search = `?search=${searchTerm}&channel=${partner.slug}&source=${partner.slug}&categories=${selectedCategory || ''}`;

    if(this.state.items.length === 0 && !this.state.loading){
      return <div style={{margin: 'auto'}}><h3>No articles right now, check back soon!</h3></div>
    }

    if(this.state.items.length > 0){
      this._loadJs()
    }

    return (
      <LazyLoadingList
        ref={this.lazyLoadingList}
        endpoint={`${window.Api.Articles}${search}`}
        onItemUpdated={items => {
          this.setState({
            items,
            loading: false
          })
        }}
        renderItem={article => {
          return (
            <ArticleItem
              partner={partner}
              article={article}
            />
          )
        }}
        renderInitialLoading={() => {
          return (
            <>
              <CompanyShimmerItem/>
              <CompanyShimmerItem/>
              <CompanyShimmerItem/>
              <CompanyShimmerItem/>
              <CompanyShimmerItem/>
              <CompanyShimmerItem/>
            </>
          )
        }}
      />
    )
  }

  render() {
    let {

    } = this.state

    return (
      <>
        <div className="row components search article-results">
          {this._renderArticles()}
        </div>
      </>
    )
  }
}
