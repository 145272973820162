import React from "react";
import $ from "jquery";

export default class Block5 extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      contentBlock: props.contentBlock
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _handleClick(link){
    if(link.includes('section-')){
      $('html, body').stop().animate({
        scrollTop: $(`${link}`).offset().top-100,
      }, 800, 'swing');
    }else{
      window.open(link)
    }
  }

  _renderContent(){
    let {
      contentBlock
    } = this.state

    return contentBlock.block_content_entry.map(content => {
      let primaryCTALink = content.primary_cta_link.includes('section-') ? `#${content.primary_cta_link}` : content.primary_cta_link
      return (
        <>
          <div className="col-lg-4">
            <div className="feature feature-3 boxed boxed--lg boxed--border">
              <i className={`icon icon--lg ${content.icon}`}/>
              <h4>{content.title}</h4>
              <p>{content.text}</p>
              <a
                style={{color: content.primary_color}}
                href={primaryCTALink}
                onClick={e => {
                  e.preventDefault()
                  this._handleClick(primaryCTALink)
                }}
              >
                {content.primary_cta_text}
              </a>
            </div>
          </div>
        </>
      )
    })
  }

  render() {
    let {
      contentBlock
    } = this.state

    return (
      <>
        <section
          className="block-05 text-center bg--primary unpad--top"
          id={`section-${contentBlock.id}`}
          style={{
            backgroundColor: contentBlock.background_color,
            padding: `${contentBlock.padding_top}px ${contentBlock.padding_right}px ${contentBlock.padding_bottom}px ${contentBlock.padding_left}px`,
            margin: `${contentBlock.margin_top}px ${contentBlock.margin_right}px ${contentBlock.margin_bottom}px ${contentBlock.margin_left}px`
          }}
        >
          <div className="container">
            <div className="row justify-content-around">
              {this._renderContent()}
            </div>
          </div>
        </section>
      </>
    )
  }
}