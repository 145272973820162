import React from "react";

import $ from 'jquery'
import Image from "../../../../utils/Image";

export default class Block2 extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      contentBlock: props.contentBlock
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderBlockStyle(){
    let {
      contentBlock
    } = this.state

    return (
      <style
        dangerouslySetInnerHTML={{__html: `
          #section-${contentBlock.id} .customSecondaryButtonBanner {
            width: 210px;
            background-color: ${contentBlock.secondary_color} !important;
            border-color: ${contentBlock.secondary_color} !important;
          }
          #section-${contentBlock.id} .customPrimaryButtonBanner {
            width: 210px;
            background-color: ${contentBlock.primary_color} !important;
            border-color: ${contentBlock.primary_color} !important;
          }
        `}}
      />
    )
  }

  _renderVideo(){
    let {
      contentBlock
    } = this.state

    let videoID = '';
    let videoURL = contentBlock.video;
    videoURL = videoURL.replace(/(>|<)/gi,'').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if(videoURL[2] !== undefined) {
      videoID = videoURL[2].split(/[^0-9a-z_\-]/i);
      videoID = videoID[0];
    } else {
      videoID = videoURL;
    }

    return (
      <div className="yt-container">
        <div className="yt-wrapper">
          <iframe
            key={videoID}
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${videoID}?controls=0&amp;autoplay=1&showinfo=0&mute=1&loop=1&playlist=${videoID}`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          />
          <div className="yt-mask"/>
        </div>
      </div>
    )
  }

  _renderImage(){
    let {
      contentBlock
    } = this.state

    let cloudFrontLink = contentBlock.background_image.includes(".gif") ? window.General.CloudFront : window.General.CloudFrontResizer

    return (
      <div className="background-image-holder">
        <img alt="image" src={Image.getImageUrl(`${cloudFrontLink}images2/topic/${contentBlock.background_image}`, 2)}/>
      </div>
    )
  }

  _handleClick(link){
    if(link.includes('section-')){
      $('html, body').stop().animate({
        scrollTop: $(`${link}`).offset().top-100,
      }, 800, 'swing');
    }else{
      window.open(link)
    }
  }

  render() {
    let {
      contentBlock
    } = this.state

    let videoClass = contentBlock.video ? 'videobg' : ''

    let secondaryCTALink = contentBlock.secondary_cta_link.includes('section-') ? `#${contentBlock.secondary_cta_link}` : `${contentBlock.secondary_cta_link}`
    let primaryCTALink = contentBlock.primary_cta_link.includes('section-') ? `#${contentBlock.primary_cta_link}` : `${contentBlock.primary_cta_link}`

    return (
      <>
        {this._renderBlockStyle()}
        <section
          className={`block-02 imagebg ${videoClass} height-${contentBlock.height} text-center`}
          data-overlay={contentBlock.overlay}
          id={`section-${contentBlock.id}`}
          style={{
            backgroundColor: contentBlock.background_color,
            padding: `${contentBlock.padding_top}px ${contentBlock.padding_right}px ${contentBlock.padding_bottom}px ${contentBlock.padding_left}px`,
            margin: `${contentBlock.margin_top}px ${contentBlock.margin_right}px ${contentBlock.margin_bottom}px ${contentBlock.margin_left}px`
          }}
        >
          { contentBlock.video && this._renderVideo() }
          { contentBlock.background_image && this._renderImage() }
          <div className="container pos-vertical-center">
            <div className="row">
              <div className="col-lg-8 col-xl-7">
                <h1 style={{color: contentBlock.text_color}}>{contentBlock.title}</h1>
                <p className="lead" style={{color: contentBlock.text_color}}>{contentBlock.text}</p>
                <a
                  className="btn btn--primary mb-md-0 mb-3 type--uppercase customSecondaryButtonBanner"
                  href={secondaryCTALink}
                  onClick={e => {
                    e.preventDefault()
                    this._handleClick(secondaryCTALink)
                  }}
                >
                  <span className="btn__text">{contentBlock.secondary_cta_text}</span>
                </a>
                <a
                  className="btn btn--primary mb-md-0 mb-3 type--uppercase customPrimaryButtonBanner"
                  href={primaryCTALink}
                  onClick={e => {
                    e.preventDefault()
                    this._handleClick(primaryCTALink)
                  }}
                >
                  <span className="btn__text">{contentBlock.primary_cta_text}</span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}