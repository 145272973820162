import React from "react";

import NavigationBar from "./components/common/NavigationBar";
import FeaturedCompanies from "./components/homepage/FeaturedCompanies";
import FeaturedJob from "./components/homepage/FeaturedJob";
import PartnerCard from "./components/homepage/PartnerCard";
import OtherCompanies from "./components/homepage/OtherCompanies";
import Feed from "./components/homepage/Feed";
import ItemsSection from "./components/common/ItemsSection";
import InfoTabs from "./components/homepage/InfoTabs";
import InfoBoxes from "./components/homepage/InfoBoxes";
import CTASection from "./components/common/CTASection";
import Footer from "./components/common/Footer";

import Backend from "../utils/Backend";
import General from "../utils/General";
import Banner from "./components/common/Banner";
import SearchBar from "./components/common/SearchBar";
import PreLoad from "./components/common/PreLoad";
import PartnerBranding from "./components/common/PartnerBranding";
import ScriptCache from "../utils/ScriptCache";
import RobinScript from "../utils/RobinScript";
import MetaTags from "../utils/MetaTags";

export default class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      partner: props.partner,
      articleData: props.articleData,
      companies: props.companies,
      subChannelData: props.subChannelData,
    }
  }

  componentDidMount() {
    this._loadJs()
    this._logTraffic()
    if(this.state.partner.settings.robin){
      this._loadRobin()
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this._logTraffic()
  }

  _loadJs(){
    ScriptCache.loadDefaults()
  }

  _loadRobin(){
    RobinScript.load(this.state.partner.slug)
  }

  _logTraffic(){
    let data = General.getTrafficData(this.state.partner)
    data["homepage"] = true
    Backend.logTraffic(data)
  }

  render() {
    let{
      partner,
      companies,
      articleData,
      subChannelData
    } = this.state

    let viewMore = window.General.CompaniesPage

    let shareTitle = `${partner.name} Job Search - Find The Latest Jobs On ${partner.name}`;
    let description = `Find jobs and careers on ${partner.name} exclusive jobs network powered by Jobbio. Learn about the companies and apply directly. Your job search starts here.`;
    let url = window.location.href;
    let shareImage = partner.logo;

    return (
      <>
        { MetaTags.render(shareTitle, description, null, url, shareImage) }
        <NavigationBar
          opacity={' bar--transparent bar--absolute '}
          partner={partner}
          showMenu={true}
        />
        <Banner
          image={partner.banner}
          overlay={partner.settings.banner_overlay}
          className={`cover height-${partner.settings.banner_size || '70'}`}
        >
          <div className="col-lg-12">
            <h1 id="name">{partner.settings.banner_title}</h1>
            <p className="lead" id="caption">{partner.caption}</p>
            <SearchBar
              page={"jobs"}
              home={true}
            />
          </div>
        </Banner>
        <FeaturedCompanies
          partner={partner}
        />
        <section className="maincolumns channel-topic sticky-sidebars">
          <div className="container">
            <div className="row">
              <Feed
                partner={partner}
              />
              <OtherCompanies
                partner={partner}
                title={partner.settings.companies_title}
                companies={companies}
                viewMore={viewMore}
              />
              <PartnerCard
                partner={partner}
              />
            </div>
          </div>
        </section>
        {
          partner.settings.show_articles &&
          <ItemsSection
            partner={partner}
            items={articleData}
            type={"articles"}
          />
        }
        {
          partner.settings.show_sub_channels &&
          <ItemsSection
            partner={partner}
            items={subChannelData}
            type={"channels"}
          />
        }
        {
          partner.settings.show_info_tabs &&
          <InfoTabs
            partner={partner}
          />
        }
        {
          partner.settings.show_info_boxes &&
          <InfoBoxes
            partner={partner}
          />
        }
        <CTASection
          title={partner.settings.floating_title}
          subline={partner.settings.floating_subline}
          url={partner.settings.floating_url}
          buttonTitle={partner.settings.floating_text}
        />
        <Footer
          partner={partner}
        />
      </>
    )
  }
}
