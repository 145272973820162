import React from "react";
import Feed from "../../common/Feed";

export default class Block3 extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      jobs: props.jobs,
      channel: props.channel,
      partner: props.partner,
      contentBlock: props.contentBlock,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderBlockStyle(){
    let {
      contentBlock
    } = this.state

    return (
      <style
        dangerouslySetInnerHTML={{__html: `
          #section-${contentBlock.id} .job-cards a.row:hover h4,
          #section-${contentBlock.id} .job-cards a.row:hover li,
          #section-${contentBlock.id} .job-cards a.row:hover i {
            color: #ffffff;
          }
          #section-${contentBlock.id} .job-cards a.row:hover {
            text-decoration: none;
            background: ${contentBlock.primary_color};
          }
          @media (min-width: 768px){
            #section-${contentBlock.id} .row.boxed.boxed--border:hover {
              cursor: pointer;
              border-color: ${contentBlock.primary_color} !important;
            }
          }
          #section-${contentBlock.id} .view-more a {
            color: ${contentBlock.tertiary_color};
          }
        `}}
      />
    )
  }

  render() {
    let {
      jobs,
      channel,
      partner,
      contentBlock
    } = this.state

    return (
      <>
        {this._renderBlockStyle()}
        <section
          className="block-03 text-center feed"
          id={`section-${contentBlock.id}`}
          style={{
            backgroundColor: contentBlock.background_color,
            padding: `${contentBlock.padding_top}px ${contentBlock.padding_right}px ${contentBlock.padding_bottom}px ${contentBlock.padding_left}px`,
            margin: `${contentBlock.margin_top}px ${contentBlock.margin_right}px ${contentBlock.margin_bottom}px ${contentBlock.margin_left}px`
          }}
        >
          <div className="container">
            <div className="row">
              <Feed
                showTitle={false}
                channel={channel.slug}
                partner={partner.slug}
                jobs={jobs.results}
                hasMore={jobs.has_more}
                pageSize={contentBlock.pagination}
              />
              <div className="col-lg-12 col-xl-12"/>
            </div>
          </div>
        </section>
      </>
    )
  }
}
