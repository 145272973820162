import React from "react";
import Image from "../../../../utils/Image";

export default class Block10 extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      contentBlock: props.contentBlock
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderBlockStyle(){

  }

  _renderContent(){
    let {
      contentBlock
    } = this.state

    let gallery = [];
    let even = true;

    contentBlock.block_content_entry.map((content, index) => {
      content['layout'] = even
      gallery.push(content)
      if((index+1) % 2){
        even = !even
      }
    });

    return gallery.map((content, index) => {
      let cloudFrontLink = content.image.includes(".gif") ? window.General.CloudFront : window.General.CloudFrontResizer
      let className = content.layout ? 'col-xl-4' : 'col-xl-8'
      return (
        <div className={`masonry__item ${className} col-lg-6 col-12`}>
          <div className="project-thumb hover-element height-50">
            <a href="javascript:void(0)">
              <div className="hover-element__initial">
                <div className="background-image-holder">
                  <img alt="background" src={Image.getImageUrl(`${cloudFrontLink}images2/topic/${content.image}`, 10)}/>
                </div>
              </div>
              <div className="hover-element__reveal" data-overlay="9">
                <div className="project-thumb__title">
                  <h5>{content.title}</h5>
                  <span>{content.subtitle}</span>
                </div>
              </div>
            </a>
          </div>
        </div>
      )
    })
  }

  render() {
    let {
      contentBlock
    } = this.state

    return (
      <>
        <section
          className="block-10 unpad"
          id={`section-${contentBlock.id}`}
          style={{
            backgroundColor: contentBlock.background_color,
            padding: `${contentBlock.padding_top}px ${contentBlock.padding_right}px ${contentBlock.padding_bottom}px ${contentBlock.padding_left}px`,
            margin: `${contentBlock.margin_top}px ${contentBlock.margin_right}px ${contentBlock.margin_bottom}px ${contentBlock.margin_left}px`
          }}
        >
          <div className="masonry masonry--gapless">
            <div
              className="masonry__container masonry--active"
              style={{position: 'relative', minHeight: 500.5}}
            >
              {this._renderContent()}
            </div>
          </div>
        </section>
      </>
    )
  }
}