import React from "react";
import FeaturedJob from "./FeaturedJob";
import Image from "../../../utils/Image";

export default class PartnerCard extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      partner: props.partner,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }


  render() {
    let {
      partner
    } = this.state

    return (
      <>
        <div className="col-lg-3 rightcol sticky-sidebar-rightcol">
          <div className="sidebar__inner">
            <div className="jobs">
              <div className="boxed boxed--border text-center">
                <div className="logo">
                  <img className="img-circle" alt="logo" src={Image.getImageUrl(partner.logo, "company-logo")}/>
                </div>
                <h4 className="unmarg">{partner.settings.detail_title}</h4>
                <p>{partner.settings.detail_subline}</p>
                <div className="buttons">
                  <a
                    className="btn btn--primary btn--sm btn-block"
                    href={`https://jobbio.com/talent/register?channel=${partner.slug}&source=${partner.slug}`}
                    target={"_blank"}
                  >
                    <span className="btn__text">
                      {partner.settings.detail_alert_cta}
                    </span>
                  </a>
                  <a
                    className="btn btn--sm btn-block"
                    href={partner.settings.detail_job_url}
                    target={"_blank"}
                  >
                    <span className="btn__text" id="detail-job">
                      {partner.settings.detail_job_cta}
                    </span>
                  </a>
                </div>
              </div>
            </div>
            {
              partner.settings.detail_featured &&
              <FeaturedJob
                partner={partner}
              />
            }
          </div>
        </div>
      </>
    )
  }
}