import React from "react";

import {Link} from "react-router-dom";
import Image from "../../../utils/Image";

export default class ArticleItem extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      partner: props.partner,
      article: props.article
    }
  }

  render() {
    let {
      partner,
      article
    } = this.state

    let link = window.General.ArticlePage.replace("[article]", article.slug)
    let title = article.title.length > 85 ? article.title.substring(0, 85) + '...' : article.title

    return (
      <>
        <div className="col-md-4 result">
          <div className="feature feature-1">

            <div className="img">
              <a 
                href={link} 
                className="bg-image d-block h-v1" 
                data-overlay="3"
                style ={{ backgroundImage: "url("+Image.getImageUrl(article.image, "company-card")+")" }}
              />
            </div>

            <div className="feature__body boxed boxed--border">
              <div className="name">
                <a href={link}>
                  {title}
                </a>
              </div>
              <div className="location">{article.description}</div>
              <div className="link">
                <a href={link}>
                  View Article
                </a>
              </div>
            </div>

          </div>
        </div>
      </>
    )
  }
}
