import React from "react";
import $ from 'jquery';
import StickyBox from "react-sticky-box";

import NavigationBar from "../common/NavigationBar";
import Footer from "../common/Footer";
import SearchBar from "../common/SearchBar";

import Indeed from "../../../utils/Indeed";
import PreLoad from "../common/PreLoad";
import ScriptCache from "../../../utils/ScriptCache";
import MetaTags from "../../../utils/MetaTags";
import JobResults from "../search/JobResults";

export default class NotFound extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      type: props.type,
      partner: props.partner,
    }
  }

  componentDidMount() {
    this._loadJs()

    // starts : search bar fixed
    $(window).scroll(function() {
      var scroll = $(window).scrollTop();
      if (scroll > 366) {
        $('.bar-search').addClass('pos-fixed');
      } else {
        $('.bar-search').removeClass('pos-fixed');
      }
    });
    // end : search bar fixed

  }

  componentWillReceiveProps(nextProps, nextContext) {

  }

  _loadJs(){
    ScriptCache.loadDefaults()
  }


  render() {
    let{
      type,
      partner,
    } = this.state

    let url = window.location.href;
    let shareImage = partner.logo;

    let canonicalLink = window.location.href

    return (
      <div className="job-v2">

        { MetaTags.noIndex(url, shareImage, canonicalLink) }

        <NavigationBar
          partner={partner}
          opacity={' bar--absolute '}
          headerFixed='true'
        />
        <div className="bar-search">
          <div className="container">
            <div className="row">
              <div className="col">
                <SearchBar
                  page={type}
                />
              </div>
            </div>
          </div>
        </div>

        {this.props.children}

        <Footer
          partner={partner}
        />

      </div>
    )
  }
}
