import React from "react";

export default class JobShimmerItem extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      page: props.page
    }
  }


  render() {
    let {
      page
    } = this.state

    let isItFromCompanyJobPage = false;
    if(page === "company-job"){
      isItFromCompanyJobPage = true;
    }

    return (
      <>
        {
          isItFromCompanyJobPage ?
            <div className="row boxed boxed--border" style={{paddingLeft: 30}}>
              <div className="col">
                <div className="line shine" style={{width: '50%'}}></div>
                <div className="line shine" style={{width: '30%'}}></div>
                <div className="line shine" style={{width: '20%'}}></div>
              </div>
            </div>
          :
            <div className="row boxed boxed--border">
              <div className="logo img-rounded shine" style={{border: 'none'}}></div>
              <div className="col-11">
                <div className="line shine"></div>
                <div className="line shine" style={{width: '50%'}}></div>
                <div className="line shine" style={{width: '30%'}}></div>
              </div>
            </div>
        }
      </>

    )
  }
}