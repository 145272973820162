import React from "react";

import {Link} from "react-router-dom";
import General from "../../../utils/General";
import Indeed from "../../../utils/Indeed";

export default class JobDescriptionLogo extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      job: props.job,
      partner: props.partner
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState({job: nextProps.job})
  }

  _renderLogo(){
    let {
      job,
      partner
    } = this.state

    let jobLink = job.redirect ? Indeed.getIndeedTrackingLink(job.redirect, partner.slug) : window.General.JobPage.replace("[company]", job.company.slug)
      .replace("[job]", job.slug)

    if(job.company.image_logo){
      return (
        <a href={jobLink} target={"_blank"}>
          <img className="logo img-rounded" src={job.company.image_logo} />
        </a>
      )
    }

    let initials = job.company.name.split(' ').map((n) => n[0]).join('');
    initials = initials.substring(0, 1);

    return (
      <a href={jobLink} className="logo img-rounded" style={{backgroundColor: '#f18f01'}}>
        <div className="initials">
          {initials}
        </div>
      </a>
    )
  }

  render() {
    let {
      job
    } = this.state

    if(job == null){
      return null
    }

    return (
      <>
        <div className="search-job-detail-logo" id="job-description-logo">
          <div className={"logo-wrapper"}>
            { this._renderLogo() }
          </div>
        </div>
      </>
    )
  }
}