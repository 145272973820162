import React from "react";
import {Link} from "react-router-dom";

export default class Footer extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      partner: props.partner
    }
  }

  render() {
    let {
      partner
    } = this.state

    return (
      <>
        <footer id="footer" className="space--sm footer-3 bg--primary">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <span className="type--fine-print">© <span className="update-year">2022</span> Jobbio</span>
                {
                  partner.settings.privacy_policy_url !== "" &&
                  <a href={partner.settings.privacy_policy_url} target={"_blank"} className="type--fine-print">Privacy Policy</a>
                }
                {
                  partner.settings.cookie_policy_url !== "" &&
                  <a href={partner.settings.cookie_policy_url} target={"_blank"} className="type--fine-print">Cookie Policy</a>
                }
                {
                  partner.settings.use_policy_url !== "" &&
                  <a href={partner.settings.use_policy_url} target={"_blank"} className="type--fine-print">Use Policy</a>
                }
                {
                  partner.settings.terms_conditions_url !== "" &&
                  <a href={partner.settings.terms_conditions_url} target={"_blank"} className="type--fine-print">Terms & Conditions</a>
                }
              </div>
              <div className="col-md-6 text-right text-left-xs">
                <ul className="social-list list-inline list--hover">
                  <li>
                    <a href="https://www.facebook.com/JobbioIreland/?brand_redir=433249880025365">
                      <i className="socicon socicon-facebook icon icon--xs"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/Jobbio">
                      <i className="socicon socicon-twitter icon icon--xs"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/jobbio/">
                      <i className="socicon socicon-linkedin icon icon--xs"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/jobbio/?hl=en">
                      <i className="socicon socicon-instagram icon icon--xs"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.jobbio.com">
                      <img src={require('../../../assets/svg/Jobbio_J_Logo_White.svg').default} alt="jobbio" style={{width:10,verticalAlign:'initial'}}/>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </>
    )
  }
}