import React from "react";
import General from "../../../utils/General";
import Indeed from "../../../utils/Indeed";
import Backend from "../../../utils/Backend";

export default class CompanyCard extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      job: props.job,
      partner: props.partner,
      variation: props.variation
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _logo(){
    let {
      job,
    } = this.state

    let link = window.General.CompanyPage.replace("[company]", job.company.slug)

    return (
      <div
        className="logo"
        style={{cursor: 'pointer'}}
        onClick={e => {
          window.location.href = link
        }}
      >
        { job.company.image_logo &&
          <img className="img-circle" alt="logo" src={job.company.image_logo}/>
        }
        { !job.company.image_logo &&
          <h2 className="img-circle">
            {General.getInitials(job.company.name)}
          </h2>
        }
      </div>
    )
  }


  render() {
    let {
      job,
      partner,
      variation
    } = this.state

    let saveUrl = `https://jobbio.com/talent/register?app_source=${partner.slug}&job=${job.id}&source=${partner.slug}`;
    let applyUrl = `https://jobbio.com/talent/register?app_source=${partner.slug}&job=${job.id}&source=${partner.slug}`;
    let followUrl = `https://jobbio.com/talent/register?company=${job.company.slug}&source=${partner.slug}`;

    if (job.redirect) {
      applyUrl = Indeed.getIndeedTrackingLink(job.redirect, partner.slug);
    }

    if (variation === "v2"){
      return(
        <div className="job-company col-lg-9 v2 mb--26">
          <div className="row">
            <div className="col-auto">
            { this._logo() }
            </div>
            <div className="col p-0">
              <div className="title mb-0 fs-16">{job.title}</div>
              <div className="name color-01">{job.company.name}</div>
              <div className="location color-01">{job.location.city || job.location.state || job.location.country}</div>
              {
                job.salary_disclosed &&
                <>
                  <div className="location color-01">
                    {job.salary}
                  </div>
                </>
              }
            </div>
            <div className="col-auto">
              <div className="buttons">
                <a
                  href={applyUrl}
                  target={"_blank"}
                  className="btn btn--sm btn--primary type--uppercase"
                  onClick={() => {
                    Backend.applyClick('job_company_card', partner)
                    if(job.redirect){
                      Backend.jobRedirect(job, partner, applyUrl)
                    }
                  }}
                >
                  <span className="btn__text">Apply</span>
                </a>
              </div>
            </div>

          </div>
        </div>
      )
    }

    return (
      <>
        <div className="col-lg-3 rightcol sticky-sidebar-rightcol">
          <div className="sidebar__inner">
            <div className="jobs">
              <div className="boxed boxed--border text-center">
                { this._logo() }
                <h4 className="unmarg">{job.company.name}</h4>
                <p>{job.location.city || job.location.state || job.location.country}</p>
                <div className="buttons">
                  <a
                    target="_blank"
                    className="btn btn--primary v1 btn--sm btn-block"
                    href={applyUrl}
                    onClick={() => {
                      Backend.applyClick('job_company_card', partner)
                      if(job.redirect){
                        Backend.jobRedirect(job, partner, applyUrl)
                      }
                    }}
                  >
                    <span className="btn__text">Apply</span>
                  </a>
                  <a target="_blank" className="btn btn--sm btn-block" href={saveUrl}>
                    <span className="btn__text">Save Job</span>
                  </a>
                  <a target="_blank" className="btn btn--primary btn--sm btn-block" href={followUrl}>
                    <span className="btn__text" id="company-follow">Follow </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
