import React from 'react'

import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react'

import General from "../../../../utils/General";

class CustomMap extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      map: JSON.parse(JSON.stringify(props.map))
    }
  }

  componentWillReceiveProps(nextState){
    this.setState({
      map: JSON.parse(JSON.stringify(nextState.map))
    })
  }

  shouldComponentUpdate(nextProps, nextState){
    let oldLocation = this.state.map.location
    let newLocation = nextState.map.location

    if(JSON.stringify(oldLocation) !== JSON.stringify(newLocation)){
      return true
    }

    return false
  }

  _renderMarker(marker){
    return <Marker position={marker} />
  }


  render(){
    let {
      map,
    } = this.state

    let { location } = map

    let { latitude, longitude } = location

    let marker = {
      lat: latitude,
      lng: longitude
    }

    return (
      <Map
        key={ General.uuid() }
        google={this.props.google}
        zoom={15}
        initialCenter={marker}
        center={marker}
        streetViewControl={false}
        mapTypeControl={false}
      >
        { this._renderMarker(marker) }
        <InfoWindow visible={true} marker={marker}>
          <div>
            <h1>{"nothing funny"}</h1>
          </div>
        </InfoWindow>
      </Map>
    )
  }
}

class CustomMarker extends Marker {
  componentDidUpdate(prevProps) {
    if (this.props.shouldRender) {
      this.marker.setMap(null)
      this.renderMarker()
    }
  }
}

export default GoogleApiWrapper({
  apiKey: window.Api.GoogleMapsKey
})(CustomMap)