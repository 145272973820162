import React from "react";

export default class PreLoad extends React.Component{
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  render() {

    return (
      <>
        <div id="preload" style={{position: 'fixed',top: 0,bottom: 0,left: 0,right: 0,zIndex: 9992,backgroundColor: 'rgb(255, 255, 255) !important'}}>
          <div style={{objectFit:'contain',height:200,width:200,position: 'absolute',transform: 'translate(-50%, -50%)',top: '50%',left: '50%',borderRadius: '50%',overflow: 'hidden'}}>
            <img src="https://dy793rr2xtptx.cloudfront.net/images2/topic/new/jobbio-logo-1559215894139.gif" style={{height: '100%',width: '100%'}}/>
          </div>
        </div>
      </>
    )
  }
}