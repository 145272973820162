import React from "react";

export default class Block14 extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      contentBlock: props.contentBlock
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderBlockStyle(){
    let {
      contentBlock
    } = this.state

    return (
      <style
        dangerouslySetInnerHTML={{__html: `
          #section-${contentBlock.id} .tabs li.active .tab__title span {
            color: ${contentBlock.primary_color};
          }
        `}}
      />
    )
  }

  _renderContent(){
    let {
      contentBlock
    } = this.state

    return contentBlock.block_content_entry.map((content, index) => {
      let tabClass = index === 0 ? 'active' : '';
      return (
        <li className={tabClass}>
          <div className={"tab__title"}>
            <span className={""}>{content.title}</span>
          </div>
          <div className="tab__content">
            <p className="lead">{content.text}</p>
          </div>
        </li>
      )
    })
  }

  render() {
    let {
      contentBlock
    } = this.state

    return (
      <>
        {this._renderBlockStyle()}
        <section
          className="block-14 text-center"
          id={`section-${contentBlock.id}`}
          style={{
            backgroundColor: contentBlock.background_color,
            padding: `${contentBlock.padding_top}px ${contentBlock.padding_right}px ${contentBlock.padding_bottom}px ${contentBlock.padding_left}px`,
            margin: `${contentBlock.margin_top}px ${contentBlock.margin_right}px ${contentBlock.margin_bottom}px ${contentBlock.margin_left}px`
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="tabs-container mt--2" data-content-align="left">
                  <ul className="tabs">
                    {this._renderContent()}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}