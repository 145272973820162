import React from "react";

import JobContractFilter from "./JobContractFilter";
import JobDatePostedFilter from "./JobDatePostedFilter";
import Backend from "../../../utils/Backend";

export default class JobFilters extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      partner: props.partner,
    }
  }

  componentDidMount() {
    this._loadFilters()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _loadFilters(){
    Backend.getFilters(this.state.partner.slug)
      .then(filters => {
        this.setState({
          filters
        })
      })
  }

  render() {
    let {
      filters
    } = this.state

    if(!filters) return null

    return (
      <>
        <form id={'filters'}>
          <div className="row m-sm-0 flex-nowrap">
            <JobContractFilter
              contracts={filters.contracts}
              setFilter={(contracts) => {
                this.props.setContractsFilters(contracts)
              }}
            />
            <JobDatePostedFilter
              setFilter={(datePosted) => {
                this.props.setDatesFilters(datePosted)
              }}
            />
          </div>
        </form>
      </>
    )
  }
}