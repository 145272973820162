import React from "react";
import General from "../../../utils/General";

export default class InfoTabs extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      partner: props.partner,
    }
  }

  componentDidMount() {
    //General.updateTabs()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderTabs(){
    let {
      partner
    } = this.state

    let check = 0;

    return partner.info.map((tab, index) => {
      if (tab.type === "content"){
        check += 1;
        let tabClass = check === 1 ? 'active' : ''
        return (
          <li className={tabClass} id={`list${tab.id}`}>
            <div className="tab__title">
              <span className="h5">
                <p>{tab.title}</p>
              </span>
            </div>
            <div className="tab__content">
              <p className="lead">{tab.text}</p>
            </div>
          </li>
        )
      }
    })
  }

  render() {
    let {

    } = this.state

    return (
      <>
        <section className="bg--primary info-tabs" id="info-tabs">
          <div className="container">
            <div className="row">
              <div className="col-md-10 col-md-offset-1">
                <div className="tabs-container tabs--vertical">
                  <ul className="tabs" style={{marginTop: 30}}>
                    {this._renderTabs()}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}