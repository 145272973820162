import React from "react";
import renderHTML from 'react-render-html';
import JobDescriptionLogo from "./JobDescriptionLogo";
import {Link} from "react-router-dom";
import General from "../../../utils/General";
import Indeed from "../../../utils/Indeed";
import Backend from "../../../utils/Backend";

export default class JobDescription extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      partner: props.partner,
      job: props.selectedJob,
      page: props.page,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState({job: nextProps.selectedJob, partner: nextProps.partner})
  }

  _applyButton(){

    let {
      job,
      partner,
      page
    } = this.state

    let applyUrl = job.redirect ? Indeed.getIndeedTrackingLink(job.redirect, partner.slug) : `https://jobbio.com/talent/register?app_source=${partner.slug}&job=${job.id}&source=${partner.slug}`;

    if(job.jobkey){
      applyUrl = job.redirect
    }

    return(
      <>
        <div className="buttons">
          <a
            href={applyUrl}
            target={"_blank"}
            className="btn btn--md btn--primary type--uppercase"
            onClick={() => {
              Backend.applyClick('search_banner', partner)
              if(job.redirect){
                Backend.jobRedirect(job, partner, applyUrl)
              }
            }}
          >
            <span className="btn__text">Apply</span>
          </a>
        </div>
      </>
    )
  }

  render() {
    let {
      job,
      partner,
      page
    } = this.state

    if(job == null){
      return null
    }

    let jobLink = job.redirect ? Indeed.getIndeedTrackingLink(job.redirect, partner.slug) : window.General.JobPage.replace("[company]", job.company.slug)
      .replace("[job]", job.slug)
    let companyLink = window.General.CompanyJobsPage.replace("[company]", job.company.slug)
    let jobPage = window.General.JobPage.replace("[company]", job.company.slug).replace("[job]", job.slug)

    if(job.jobkey){
      jobLink = job.redirect
      companyLink = job.redirect
    }

    return (
      <>
        <div className="col-lg-12 search-job-detail">
          <div className="sidebar__inner">
            <div className="search-job-detail-container">

              <div className="info">
                <JobDescriptionLogo
                  job={job}
                  partner={partner}
                />
                <div className="details-top imagebg text-center">
                  <a href={jobPage}>
                    <h1 style={{cursor:'pointer', marginTop:50, fontSize: '2.1em'}}>{job.title}</h1>
                  </a>
                  <ul className="list-inline list-v1">
                    <li className="color--primary">
                      <i className="icon icon--sm icon-Suitcase"></i>
                      <a href={companyLink} style={{fontWeight: 'normal'}}><span className="color--primary">{job.company.name}</span></a>
                    </li>
                    <li style={{color: '#2525252'}}>
                      <i className="icon icon--sm icon-Map-Marker"></i>{job.location.city || job.location.state || job.location.country}
                    </li>
                    {
                      job.salary_disclosed &&
                      <>
                        <li style={{color: '#2525252'}}>
                          <i className="icon icon--sm icon-Coins"></i>
                          {job.salary}
                        </li>
                      </>
                    }
                  </ul>
                </div>
              </div>

              <div className="row boxed boxed--border post post-text">
                {renderHTML(job.description.slice(0, 500))}...<a
                  href={jobLink}
                  target={"_blank"}
                  style={{color:partner.colour, fontWeight: 'normal'}}
                  onClick={() => {
                    if(job.redirect){
                      Backend.jobRedirect(job, partner, jobLink)
                    }
                  }}
                >
                  See Full Description
                </a>
              </div>

              <div className="btm-button">
                {this._applyButton()}
              </div>

            </div>
          </div>
        </div>
      </>
    )
  }
}
