import React from "react";

export default class Block4 extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      contentBlock: props.contentBlock
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderBlockStyle(){

  }

  render() {
    let {
      contentBlock
    } = this.state

    return (
      <>
        <section
          className="block-04 text-center"
          id={`section-${contentBlock.id}`}
          style={{
            backgroundColor: contentBlock.background_color,
            padding: `${contentBlock.padding_top}px ${contentBlock.padding_right}px ${contentBlock.padding_bottom}px ${contentBlock.padding_left}px`,
            margin: `${contentBlock.margin_top}px ${contentBlock.margin_right}px ${contentBlock.margin_bottom}px ${contentBlock.margin_left}px`
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-xl-8">
                <form
                  className="form--inline form-email"
                  data-success="Thanks for your enquiry, we'll be in touch shortly."
                  data-error="Please fill in all fields correctly." noValidate="true"
                >
                  <span className="h4 inline-block">Hi, I'm</span>
                  <input type="text" name="name" placeholder="Full Name" className="validate-required"/>
                  <span className="h4 inline-block">and I currently work as a</span>
                  <input type="text" name="Job" placeholder="Job Title" className="validate-required"/>
                  <span className="h4 inline-block"> for </span>
                  <input type="text" name="Company" placeholder="Company Name" className="validate-required"/>
                  <span className="h4 inline-block">I'm interested in a</span>
                  <div className="input-select">
                    <select className="validate-required">
                      <option selected="selected" value="">Marketing</option>
                      <option value="Design">Sales</option>
                      <option value="Development">Technology</option>
                    </select>
                  </div>
                  <span className="h4 inline-block">job, and I can start on</span>
                  <input type="text" name="Date" placeholder="Date / Timeframe" className="validate-required"/>
                  <span className="h4 inline-block">You can contact me via</span>
                  <input type="email" name="email" placeholder="Email Address" className="validate-required validate-email"/>
                  <span className="h4 inline-block">or</span>
                  <input type="tel" name="Phone" placeholder="Phone Number" className="validate-required"/>
                  <div className="mt--1">
                    <button type="submit" className="btn btn--primary type--uppercase">Submit My Details</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}