import React from "react";

export default class InfoBoxes extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      partner: props.partner,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderBoxes(){
    let {
      partner
    } = this.state

    return partner.info.map((item, index) => {
      if (item.type === "box"){
        return (
          <div className="col-md-4">
            <div className="feature feature-4 boxed boxed--lg boxed--border">
              <i className={`icon ${item.icon}`}/>
              <h4>{item.title}</h4>
              <hr/>
                <p>{item.text}</p>
                <a target="_blank" className="btn btn--primary" href={item.cta_url}>
                  <span className="btn__text">{item.cta_text}</span>
                </a>
            </div>
          </div>
        )
      }
    })
  }

  render() {
    let {

    } = this.state

    return (
      <>
        <section id="info-boxes">
          <div className="container">
            <div className="row" id="info-boxes-list">
              {this._renderBoxes()}
            </div>
          </div>
        </section>
      </>
    )
  }
}