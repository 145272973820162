import React from "react";

import {Link} from "react-router-dom";

export default class Banner extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      article: props.article,
      partner: props.partner
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  render() {
    let {
      article,
      partner
    } = this.state

    return (
      <>
        <div className="col-md-12 text-center">
          <h1>{article.title}</h1>
        </div>
      </>
    )
  }
}