import React from "react";

export default class CTASection extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      title: props.title,
      subline: props.subline,
      url: props.url,
      buttonTitle: props.buttonTitle,
      finePrintLink: props.finePrintLink,
      finePrintText: props.finePrintText
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }


  render() {
    let {
      title,
      subline,
      url,
      buttonTitle,
      finePrintLink,
      finePrintText
    } = this.state

    return (
      <>
        <section className="text-center">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-lg-6">
                <div className="cta">
                  <h2 id="floating-title">{title}</h2>
                  <p className="lead" id="floating-subline">{subline}</p>
                  <a className="btn btn--primary type--uppercase" id="floating-url" href={url}>
                    <span>
                      <b style={{color: '#ffffff'}}>
                        {buttonTitle}
                      </b>
                    </span>
                  </a>
                  {
                    this.props.finePrint &&
                    <p className="type--fine-print">
                      view other&nbsp;
                      <a href={finePrintLink}>
                        {finePrintText}
                      </a>&nbsp;roles
                    </p>
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

CTASection.defaultProps = {
  finePrint: false
}