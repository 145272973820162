import React from "react";

import {Link} from "react-router-dom";
import General from "../../../utils/General";
import Image from "../../../utils/Image";

export default class CompanyItem extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      partner: props.partner,
      company: props.company
    }
  }

  render() {
    let {
      partner,
      company
    } = this.state

    let thumbnail = company.image_card ? company.image_card : General.getRandomBannerImage()

    let link = company.branding_channel ? window.General.CompanyPage.replace("[company]", company.slug) : window.General.CompanyJobsPage.replace("[company]", company.slug)
    let ctaText = company.live_jobs > 0 ? `View ${company.live_jobs} Jobs` : 'View Company'

    return (
      <>
        <div className="col-md-4 result">
          <div className="feature feature-1">

            <div className="img">
              <a 
                href={link} 
                className="bg-image d-block h-v1" 
                data-overlay="3"
                style ={{ backgroundImage: "url("+Image.getImageUrl(thumbnail, "company-card")+")" }}
              />
            </div>

            <div className="feature__body boxed boxed--border">
              <img className="amply-pxl" data-src={`${window.Api.PixelTracker}?company_id=${company.id}&source=${partner.slug}&url=${encodeURIComponent(window.location.href)}`} width="0" height="0" style={{display: 'block', marginBottom:-1}} />
              <div className="name">
                <a href={link}>
                  {company.name}
                </a>
              </div>
              <div className="link">
                <a href={link}>
                  {ctaText}
                </a>
              </div>
            </div>

          </div>
        </div>
      </>
    )
  }
}