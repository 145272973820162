import React from "react";

export default class CompanyShimmerItem extends React.Component{
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  render() {
    let {

    } = this.state

    return (
      <>
        <div className="col-lg-4 result">
          <article className="feature feature-1">
            <div className="line shine" style={{height: '197px'}}></div>
            <div className="feature__body boxed boxed--border">
              <div style={{width: '50%',height: '20px'}} className="line shine"></div>
              <div style={{width: '20%',marginTop: '15px'}} className="line shine"></div>
              <div style={{width: '30%',marginTop: '15px'}} className="line shine"></div>
            </div>
          </article>
        </div>
      </>
    )
  }
}