import React from 'react';
import { Helmet } from 'react-helmet';

export default class MetaTags {
  static render(title, description, keywords, url, imageUrl, canonicalLink=null, type='website'){

    imageUrl = imageUrl || process.env.REACT_APP_JOB_BOARD_SITE_URL + "/assets/img/logo-dark.png"
    let children = []
    if(title){
      children.push(<title>{title}</title>)
      children.push(<meta property="og:title" content={title} />)
    }

    if(description){
      children.push(<meta name="description" content={description} />)
      children.push(<meta name="og:description" content={description} />)
    }

    if(keywords){
      children.push(<meta name="keywords" content={keywords} />)
    }

    if(imageUrl){
      children.push(<meta property="og:image" content={imageUrl} />)
    }

    if(url){
      children.push(<meta property="og:url" content={url} />)
    }

    if(url){
      children.push(<meta property="og:type" content={type} />)
    }

    if(canonicalLink){
      children.push(<link rel="canonical" href={canonicalLink}/>)
    }

    return (
      <Helmet>
        { children }
      </Helmet>
    )
  }

  static noIndex(url, imageUrl, canonicalLink){
    return (
      <Helmet>
        <title>Not Found</title>
        <meta property="og:title" content="Not Found" />
        <meta name="description" content="The detail you are looking for is not found." />
        <meta name="og:description" content="The detail you are looking for is not found." />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={canonicalLink}/>
        <meta name="robots" content="noindex"/>

      </Helmet>
    )
  }
}