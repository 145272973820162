import React from "react";

export default class Block13 extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      contentBlock: props.contentBlock
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderBlockStyle(){
    let {
      contentBlock
    } = this.state

    return (
      <style
        dangerouslySetInnerHTML={{__html: `
          #section-${contentBlock.id} .process-1:before {
            background: ${contentBlock.primary_color};
          }
          #section-${contentBlock.id} .process__item:after {
            border-color: ${contentBlock.primary_color};
            background: ${contentBlock.background_color};
          }
          #section-${contentBlock.id} .customTitleColorTimeLine {
            color: ${contentBlock.text_color} !important;
          }
          #section-${contentBlock.id} .customTextColorTimeLine {
            color: ${contentBlock.tertiary_color} !important;
          }
        `}}
      />
    )
  }

  _renderContent(){
    let {
      contentBlock
    } = this.state

    return contentBlock.block_content_entry.map(content => {
      return (
        <div className="process__item">
          <h3 className="customTitleColorTimeLine">{content.title}</h3>
          <p className="customTextColorTimeLine">{content.text}</p>
        </div>
      )
    })
  }

  render() {
    let {
      contentBlock
    } = this.state

    return (
      <>
        {this._renderBlockStyle()}
        <section
          className="block-13"
          id={`section-${contentBlock.id}`}
          style={{
            backgroundColor: contentBlock.background_color,
            padding: `${contentBlock.padding_top}px ${contentBlock.padding_right}px ${contentBlock.padding_bottom}px ${contentBlock.padding_left}px`,
            margin: `${contentBlock.margin_top}px ${contentBlock.margin_right}px ${contentBlock.margin_bottom}px ${contentBlock.margin_left}px`
          }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-12">
                <div className="process-1">
                  {this._renderContent()}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}