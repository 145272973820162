import React from "react";

import {Link} from "react-router-dom";

export default class Banner extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      channel: props.channel,
      partner: props.partner
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  render() {
    let {
      channel,
      partner
    } = this.state

    let followUrl = `https://jobbio.com/talent/register?company=${channel.slug}&source=${partner.slug}`;

    return (
      <>
        <div className="col-md-12 text-center">
          <h1>{channel.name}</h1>
          <h5 id="caption">{channel.caption}</h5>
          <a data-scroll className="btn btn--sm btn--primary type--uppercase" href="#jobs-list">
            <span className="btn__text">VIEW JOBS</span>
          </a>
          <a target="_blank" className="btn btn--sm type--uppercase" href={followUrl}>
            <span className="btn__text">FOLLOW CHANNEL</span>
          </a>
        </div>
      </>
    )
  }
}