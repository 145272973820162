import React, { Component } from 'react';
import Pagination from "react-js-pagination";

import LazyLoader from './LazyLoader'
import List from './List'
import AuthManager from '../../../utils/AuthManager'

import {
  isMobile
} from "react-device-detect";

export default class Paginator extends Component  {

  constructor(props){
    super(props);
    this.state = {
      endpoint: props.endpoint,
      pageCount: props.pageCount,
      items: [],
      nextUrl: null,
      isInitialLoading: true,
    }
    this.lazyLoader = React.createRef()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  refresh(){
    this.lazyLoader.current._refresh()
  }

  _renderList(){

    let {
      items,
      isInitialLoading
    } = this.state

    if(isInitialLoading){
      return this.props.renderInitialLoading()
    }

    return (
      <List
        items={items}
        renderItem={(item) => this.props.renderItem(item)}
        loadMore={() => {}}
      />
    )
  }

  _handleNextPage(page){
    let currentUrl = window.location.href

    if (currentUrl.includes('?')){
      if(currentUrl.includes("?page=")){
        window.location.href = `${currentUrl.split('?page=')[0]}?page=${page}`
      }else if(currentUrl.includes("&page=")){
        window.location.href = `${currentUrl.split('&page=')[0]}&page=${page}`
      }else{
        window.location.href = `${currentUrl}&page=${page}`
      }
    }else{
      window.location.href = `${currentUrl}?page=${page}`
    }
  }

  _setHref(page){
    let currentUrl = window.location.href

    if(currentUrl.includes('?')){
      if(currentUrl.includes("?page=")){
        return `${currentUrl.split('?page=')[0]}?page=${page}`
      }else if(currentUrl.includes("&page=")){
        return `${currentUrl.split('&page=')[0]}&page=${page}`
      }else{
        return `${currentUrl}&page=${page}`
      }
    }else{
      return `${currentUrl}?page=${page}`
    }
  }

  render(){
    let {
      endpoint,
      items,
      nextUrl,
      pageCount,
      count,
      isInitialLoading
    } = this.state

    let nextPageCount = parseInt(pageCount) + 1

    return (
      <>
        <LazyLoader
          ref={this.lazyLoader}
          endpoint={endpoint}
          mode={"page"}
          params={{page:pageCount}}
          headers={AuthManager.getHeaders()}
          onItemsUpdated={(items, nextUrl, count) => {
            this.props.onItemUpdated(items)
            this.setState({items, count, nextUrl, isInitialLoading: false})
          }}
          onRefresh={items => (
            this.setState({items:[], isInitialLoading: true})
          )}
        >
          { this._renderList() }
        </LazyLoader>
        {
          (count > 20 && !isMobile) &&
          <Pagination
            activePage={parseInt(pageCount)}
            itemsCountPerPage={20}
            totalItemsCount={count}
            pageRangeDisplayed={5}
            hideFirstLastPages={true}
            hideDisabled={true}
            innerClass={"pagination react-js-pagination"}
            itemClass={"page-item"}
            linkClass={"page-link"}
            activeClass={"active"}
            onChange={(page) => this._handleNextPage(page)}
            getPageUrl={page => {
              return this._setHref(page)
            }}
          />
        }
        {
          (count > 20 && isMobile) &&
          <a
            href={this._setHref(nextPageCount)}
            className={"btn btn--primary type--uppercase w-100 react-js-pagination"}
          >
            <span className="btn__text">Next Page</span>
          </a>
        }
      </>
    )
  }


}

Paginator.defaultProps = {
  loadOnce: false,
  renderInitialLoading: () => <div/>,
  onItemUpdated: () => null
}