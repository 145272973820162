import React from "react";
import Image from "../../../utils/Image";
import General from "../../../utils/General";

export default class Banner extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      className: props.className,
      image: props.image,
      children: props.children,
      overlay: props.overlay
    }
  }

  componentDidMount() {
    General.updateImageBackgrounds()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  render() {
    let {
      image,
      overlay,
      children,
      className
    } = this.state

    return (
      <>
        <section className={"imagebg text-center job-banner " + className} data-overlay={overlay || "8"} style={{zIndex: 0}}>
          <div className="background-image-holder">
            <img alt="background" src={Image.getImageUrl(image, "banner", 60)}/>
          </div>
          <div className="container pos-vertical-center">
            <div className="row">
              {children}
            </div>
          </div>
        </section>
      </>
    )
  }
}