import React from "react";

export default class Block12 extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      contentBlock: props.contentBlock
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderBlockStyle(){

  }

  _renderInstagram(){
    let{
      contentBlock
    } = this.state

    return (
      <section
        className="block-12 text-center"
        id={`section-${contentBlock.id}`}
        style={{
          backgroundColor: contentBlock.background_color,
          padding: `${contentBlock.padding_top}px ${contentBlock.padding_right}px ${contentBlock.padding_bottom}px ${contentBlock.padding_left}px`,
          margin: `${contentBlock.margin_top}px ${contentBlock.margin_right}px ${contentBlock.margin_bottom}px ${contentBlock.margin_left}px`
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="instafeed"
                data-user-name="${contentBlock.social_link}"
                data-amount="12"
                data-grid="6"
              />
              <a
                className="btn btn--icon bg--instagram type--uppercase"
                href="#"
              >
                <span className="btn__text">
                  <i className="socicon socicon-instagram"></i>
                  Follow Us
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>
    )
  }

  _renderTwitter(){
    let {
      contentBlock
    } = this.state

    return (
      <section
        className="text-center imagebg"
        id={`section-${contentBlock.id}`}
        style={{
          backgroundColor: contentBlock.background_color,
          padding: `${contentBlock.padding_top}px ${contentBlock.padding_right}px ${contentBlock.padding_bottom}px ${contentBlock.padding_left}px`,
          margin: `${contentBlock.margin_top}px ${contentBlock.margin_right}px ${contentBlock.margin_bottom}px ${contentBlock.margin_left}px`
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-xl-6">
              <div
                className="text-left tweets-feed tweets-feed-1 bg--secondary"
                data-feed-name={contentBlock.social_link}
                data-amount="3"
              />
              <a
                className="btn btn--icon bg--twitter"
                href={`https://twitter.com/${contentBlock.social_link}`}
                target="_blank"
              >
                <span className="btn__text">
                  <i className="socicon socicon-twitter"></i>
                  Visit Us On Twitter
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>
    )
  }

  render() {
    let {
      contentBlock
    } = this.state

    return (
      <>
        {
          contentBlock.social_type === "instagram" ?
            this._renderInstagram() :
            this._renderTwitter()
        }
      </>
    )
  }
}