import React from "react";

export default class Block7 extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      contentBlock: props.contentBlock
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderBlockStyle(){

  }

  render() {
    let {
      contentBlock
    } = this.state

    return (
      <>
        <section
          className="block-07 video video-1 text-center"
          id={`section-${contentBlock.id}`}
          style={{
            backgroundColor: contentBlock.background_color,
            padding: `${contentBlock.padding_top}px ${contentBlock.padding_right}px ${contentBlock.padding_bottom}px ${contentBlock.padding_left}px`,
            margin: `${contentBlock.margin_top}px ${contentBlock.margin_right}px ${contentBlock.margin_bottom}px ${contentBlock.margin_left}px`
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="modal-instance">
                  <div className="video-play-icon video-play-icon--sm modal-trigger box-shadow"/>
                  <div className="modal-container">
                    <div className="modal-content bg-dark" data-width="60%" data-height="60%">
                      <iframe data-src={contentBlock.video} allowFullScreen="allowfullscreen"/>
                    </div>
                  </div>
                </div>
                <h2 style={{color: contentBlock.text_color}}>{contentBlock.title} </h2>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}