import React from "react";

import General from "../../../utils/General";
import Indeed from "../../../utils/Indeed";
import Backend from "../../../utils/Backend";

export default class Banner extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      job: props.job,
      partner: props.partner
    }
  }

  componentDidMount() {
    General.updateImageBackgrounds()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  render() {
    let {
      job,
      partner
    } = this.state

    let applyUrl = `https://jobbio.com/talent/register?app_source=${partner.slug}&job=${job.id}&source=${partner.slug}`;

    if (job.redirect) {
      applyUrl = Indeed.getIndeedTrackingLink(job.redirect, partner.slug);
    }

    return (
      <>
        <div className="col-md-12 text-center">
          <h1>{job.title}</h1>
          <ul className="list-inline list-v1">
            <li className="color--primary" id="company">
              <i className="icon icon--sm icon-Suitcase"></i>
              {job.company.name}
            </li>
            <li className="color--primary" id="location">
              <i className="icon icon--sm icon-Map-Marker"></i>
              {job.location.city || job.location.state || job.location.country}
            </li>
          </ul>
          <div className="buttons">
            <a
              target="_blank" className="btn btn--md btn--primary type--uppercase" href={applyUrl}
              onClick={() => {
                Backend.applyClick('job_banner', partner)
                if(job.redirect){
                  Backend.jobRedirect(job, partner, applyUrl)
                }
              }}
            >
              <span className="btn__text">Apply</span>
            </a>
          </div>
        </div>
      </>
    )
  }
}
