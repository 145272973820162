import React from "react";
import General from "../../../../utils/General";
import Image from "../../../../utils/Image";

export default class Block15 extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      contentBlock: props.contentBlock
    }
  }

  componentDidMount() {
    setTimeout(() => {
      //General.updateImageBackgrounds()
    }, 3000)
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderImage(image){
    let cloudFrontLink = image.includes(".gif") ? window.General.CloudFront : window.General.CloudFrontResizer
    return (
      <div className="background-image-holder">
        <img alt="image" src={Image.getImageUrl(`${cloudFrontLink}images2/topic/${image}`, 15)}/>
      </div>
    )
  }

  _renderVideo(video){
    return (
      <div className="modal-instance">
        <div className="video-play-icon modal-trigger"/>
        <div className="modal-container">
          <div className="modal-content bg-dark" data-width="60%" data-height="60%">
            <iframe data-src={`${video}`} allowFullScreen="allowfullscreen"/>
          </div>
        </div>
      </div>
    )
  }

  _renderBlock(){
    let {
      contentBlock
    } = this.state

    return contentBlock.block_content_entry.map((content, index) => {
      let sectionClassName = index % 2 === 0 ? 'switchable' : '';
      let contentClassName = index % 2 === 0 ? 'pos-right' : 'pos-left'
      let innerClassName = index % 2 === 0 ? '' : 'offset-md-7 offset-sm-5'
      return (
        <section
          className={`block-15 imageblock ${sectionClassName} feature-large switchable--switch bg--secondary`}
          id={`section-${contentBlock.id}`}
          style={{
            backgroundColor: contentBlock.background_color,
            padding: `${contentBlock.padding_top}px ${contentBlock.padding_right}px ${contentBlock.padding_bottom}px ${contentBlock.padding_left}px`,
            margin: `${contentBlock.margin_top}px ${contentBlock.margin_right}px ${contentBlock.margin_bottom}px ${contentBlock.margin_left}px`
          }}
        >
          <div className={`imageblock__content col-xl-6 col-lg-4 ${contentClassName}`}>
            {content.image && this._renderImage(content.image)}
            {content.video && this._renderVideo(content.video)}
          </div>
          <div className="container">
            <div className="row">
              <div className={`col-lg-5 col-md-7 ${innerClassName}`}>
                <h2 style={{color: contentBlock.text_color}}>{content.title}</h2>
                <p className="lead" style={{color: contentBlock.tertiary_color}}>{content.text}</p>
              </div>
            </div>
          </div>
        </section>
      )
    })
  }

  render() {
    return (
      <>
        {this._renderBlock()}
      </>
    )
  }
}