import React from "react";

export default class Block6 extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      contentBlock: props.contentBlock
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderBlockStyle(){
    let {
      contentBlock
    } = this.state

    return (
      <style
        dangerouslySetInnerHTML={{__html: `
          #section-${contentBlock.id} a.modal-trigger:hover {
            border-color: ${contentBlock.primary_color} !important;
          }
        `}}
      />
    )
  }

  render() {
    let {
      contentBlock
    } = this.state

    return (
      <>
        { this._renderBlockStyle() }
        <section
          className="block-06 space--xxs text-center"
          id={`section-${contentBlock.id}`}
          style={{
            backgroundColor: contentBlock.background_color,
            padding: `${contentBlock.padding_top}px ${contentBlock.padding_right}px ${contentBlock.padding_bottom}px ${contentBlock.padding_left}px`,
            margin: `${contentBlock.margin_top}px ${contentBlock.margin_right}px ${contentBlock.margin_bottom}px ${contentBlock.margin_left}px`
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="modal-instance">
                  <button
                    className="btn type--uppercase modal-trigger"
                    style={{
                      backgroundColor: contentBlock.primary_color,
                      borderColor: contentBlock.primary_color,
                      paddingLeft: '2.78571429em',
                      paddingRight: '2.78571429em'
                    }}
                  >
                    <span className="btn__text" style={{color: contentBlock.text_color}}>
                      ▶ {contentBlock.primary_cta_text}
                    </span>
                  </button>
                  <div className="modal-container" id={`smallVideo${contentBlock.id}`}>
                    <div className="modal-content bg-dark" data-width="60%" data-height="60%">
                      <iframe
                        data-src={contentBlock.video}
                        src={contentBlock.video}
                        allowFullScreen="allowfullscreen"
                      />
                    </div>
                  </div>
                </div>
                <span className="block--xs" style={{color: contentBlock.text_color}}>
                  ${contentBlock.title}
                </span>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}