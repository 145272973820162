const env = process.env;

window.Api = {};

window.Api.Base = env.REACT_APP_API_BASE

window.Api.User = {};

window.Api.User.Base = window.Api.Base + "/user"

window.Api.User.Login                 = window.Api.User.Base + "/login"
window.Api.User.Logout                = window.Api.User.Base + "/logout"
window.Api.User.Info                  = window.Api.User.Base + "/info"
window.Api.User.RequestResetPassword  = window.Api.User.Base + "/request-reset-password"
window.Api.User.ResetPassword         = window.Api.User.Base + "/reset-password"
window.Api.User.RefreshToken          = window.Api.User.Base + "/login/refresh"

window.Api.Register = window.Api.Base + "/login"

window.Api.Jobs         = window.Api.Base + "/jobs"
window.Api.Companies    = window.Api.Base + "/companies"
window.Api.Channels     = window.Api.Base + "/channels"
window.Api.Channel      = window.Api.Base + "/channel"
window.Api.Articles     = window.Api.Base + "/articles"
window.Api.Blocks       = window.Api.Base + "/blocks/content"
window.Api.Categories   = window.Api.Base + "/categories"
window.Api.Redirects    = window.Api.Base + "/jobs/redirect"
window.Api.View         = window.Api.Base + "/jobs/view"
window.Api.Clicks       = window.Api.Base + "/clicks"
window.Api.Search       = window.Api.Base + "/search"
window.Api.Locations    = window.Api.Base + "/location-autocomplete"
window.Api.Traffic      = window.Api.Base + "/traffic"
window.Api.PixelTracker = window.Api.Base + "/pixels/amply.gif"