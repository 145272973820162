import React from "react";
import {Link} from "react-router-dom";
import renderHTML from 'react-render-html';
import Image from "../../../utils/Image";
import General from "../../../utils/General";

export default class FeaturedCompanies extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      partner: props.partner,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderMainCompany(){
    let {
      partner
    } = this.state

    let company = partner.featured_companies[0];

    let ctaText = company.live_jobs > 0 ? `VIEW ${company.live_jobs} JOBS` : 'VIEW COMPANY'
    let ctaColor = company.colour ? company.colour : '#444444';

    let link = company.branding_channel ? window.General.CompanyPage.replace("[company]", company.slug) : window.General.CompanyJobsPage.replace("[company]", company.slug)

    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="height-50 imagebg border--round" data-overlay="4">
              <div className="background-image-holder">
                <img alt="background" src={Image.getImageUrl(company.image_banner || General.getRandomBannerImage(), "primary-featured-company", 60)}/>
              </div>
              <div className="pos-vertical-center col-lg-6 boxed boxed--lg bg--none">
                <div className="s-logo">
                  <img className="amply-pxl" data-src={`${window.Api.PixelTracker}?company_id=${company.id}&source=${partner.slug}&url=${encodeURIComponent(window.location.href)}`} width="0" height="0" style={{display: 'block', marginBottom:-1}} />
                  <img src={company.image_logo}/>
                </div>
                <h1>{company.name}</h1>
                <p className="lead">{company.location.city}, {company.location.country}</p>
                <a
                  href={link}
                  className="btn btn--sm btn--primary"
                  style={{backgroundColor: `${ctaColor}`, borderColor: `${ctaColor}`}}
                >
                  {ctaText}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  _renderOtherCompanies(){
    let {
      partner
    } = this.state

    let companies = [...partner.featured_companies]
    companies.shift()

    return companies.map(company => {
      let max = 12, min = 1;
      let randomNumber = Math.floor(Math.random() * (max - min + 1)) + min
      let imageUrl = company.image_card || `${window.General.CloudFrontResizer}images/site/company-placeholders/default-image${randomNumber}.jpg`

      let cardLines = '';
      company.cards.forEach(card => {
        cardLines = cardLines + `<li><i class="fa ${card.icon}" aria-hidden="true"></i> ${card.text}</li>`
      })

      let ctaText = company.live_jobs > 0 ? `VIEW ${company.live_jobs} JOBS` : 'VIEW COMPANY'
      let ctaColor = company.colour ? company.colour : '#444444';

      let link = company.branding_channel ? window.General.CompanyPage.replace("[company]", company.slug) : window.General.CompanyJobsPage.replace("[company]", company.slug)

      return (
        <div className="col-md-4 tile">
          <div className="feature feature-1">
            <div className="img">
              <a 
                className="d-block" 
                href={link}
              >
                <span 
                  className="bg-image d-block h-v1" 
                  style ={{ backgroundImage: "url("+Image.getImageUrl(imageUrl, "company-card")+")" }}
                />
              </a>
            </div>
            <div className="feature__body boxed boxed--border">
              <div className="s-logo">
                <img className="amply-pxl" data-src={`${window.Api.PixelTracker}?company_id=${company.id}&source=${partner.slug}&url=${encodeURIComponent(window.location.href)}`} width="0" height="0" style={{display: 'block', marginBottom:-1}} />
                <img src={`${company.image_logo}`}/>
              </div>
              <h4>{company.name}</h4>
              <ul style={{textAlign: 'left'}}>{renderHTML(cardLines)}</ul>
              <a
                href={link}
                className="btn btn--primary" style={{backgroundColor: `${ctaColor}`}}
              >
                <span className="btn__text">{ctaText}</span>
              </a>
            </div>
          </div>
        </div>
      )
    })
  }

  render() {
    let {
      partner
    } = this.state

    return (
      <>
        {
          partner.featured_companies.length > 0 &&
          <div className="components homepage featured-companies">

            <section className="text-center">
              <div className="container">
                <div className="row">
                  <div className="col-lg-10 col-xl-8">
                    <h1 id="featured-companies-title">{partner.settings.featured_companies_title}</h1>
                    <p className="lead" id="featured-companies-subtitle">{partner.settings.featured_companies_subtitle}</p>
                  </div>
                </div>
              </div>
            </section>

            <section className="space--sm switchable switchable--switch" id="main-company">
              {this._renderMainCompany()}
            </section>

            <section id="video-tiles" className="video-tiles images">
              <div className="container">
                <div className="row tiles-v1" id="other-companies">
                  {this._renderOtherCompanies()}
                </div>
              </div>
            </section>
            
          </div>
        }
      </>
    )
  }
}