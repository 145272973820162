import React from "react";
import Image from "../../../../utils/Image";
import General from "../../../../utils/General";

export default class Block9 extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      contentBlock: props.contentBlock
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderBlockStyle(){
    let {
      contentBlock
    } = this.state

    return (
      <style
        dangerouslySetInnerHTML={{__html: `
          #section-${contentBlock.id} .article__title,
          #section-${contentBlock.id} .article__description {
            color: ${contentBlock.text_color};
          }
          
          #section-${contentBlock.id} .article__link {
            color: ${contentBlock.tertiary_color} !important;
          }
        `}}
      />
    )
  }

  _renderContent(){
    let {
      contentBlock
    } = this.state

    return contentBlock.block_content_entry.map(content => {
      let cloudFrontLink = content.image.includes(".gif") ? window.General.CloudFront : window.General.CloudFrontResizer
      let title = content.title.length > 85 ? content.title.substring(0, 85) + '...' : content.title
      let articleLink = content.primary_cta_link
      return (
        <div className="col-md-4 result">
          <div className="feature feature-1">
            <div className="img">
              <a
                href={articleLink}
                target="_blank"
                className="bg-image d-block h-v1"
                data-overlay="3"
                style ={{ backgroundImage: "url("+Image.getImageUrl(`${cloudFrontLink}images2/topic/${content.image}`, "company-card")+")" }}
              />
            </div>
            <div className="feature__body boxed boxed--border">
              <div className="name">
                <a href={articleLink} target="_blank" className="article__title">
                  {title}
                </a>
              </div>
              <div className="article__description">{content.text}</div>
              <div className="link">
                <a href={articleLink} target="_blank" className="article__link">
                  {content.primary_cta_text}
                </a>
              </div>
            </div>
          </div>
        </div>
      )
    })
  }

  render() {
    let {
      contentBlock
    } = this.state

    return (
      <>
        {this._renderBlockStyle()}
        <section
          className="components common items-section"
          id={`section-${contentBlock.id}`}
          style={{
            backgroundColor: contentBlock.background_color,
            padding: `${contentBlock.padding_top}px ${contentBlock.padding_right}px ${contentBlock.padding_bottom}px ${contentBlock.padding_left}px`,
            margin: `${contentBlock.margin_top}px ${contentBlock.margin_right}px ${contentBlock.margin_bottom}px ${contentBlock.margin_left}px`
          }}
        >
          <div className="container">
            <div className="row justify-content-around">
              {this._renderContent()}
            </div>
          </div>
        </section>
      </>
    )
  }
}