import React from "react";
import General from "../../../utils/General";
import Image from "../../../utils/Image";

export default class CompanyCard extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      channel: props.channel,
      partner: props.partner
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }


  render() {
    let {
      channel,
      partner
    } = this.state

    let followUrl = `https://jobbio.com/talent/register?company=${channel.slug}&source=${partner.slug}`;

    return (
      <>
        <div className="col-lg-3 rightcol sticky-sidebar-rightcol">
          <div className="sidebar__inner">
            <div className="jobs">
              <div className="boxed boxed--border text-center">
                <div className="logo">
                  { channel.logo && 
                    <img className="img-circle" alt="logo" src={Image.getImageUrl(channel.logo, "company-logo")}/>
                  }
                  { !channel.logo && 
                    <h2 className="img-circle">
                      {General.getInitials(channel.name)}
                    </h2>
                  }
                </div>
                <h4 className="unmarg">{channel.name}</h4>
                <p/>
                <div className="buttons">
                  <a target="_blank" className="btn btn--primary btn--sm btn-block" href={followUrl}>
                    <span className="btn__text" id="company-follow">Follow {channel.name}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}