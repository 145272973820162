import React from "react";

import JobItem from "../common/JobItem";
import Backend from "../../../utils/Backend";
import FetchHelper from "../../../utils/FetchHelper";
import {Link} from "react-router-dom";
import {DebounceInput} from 'react-debounce-input';

export default class Feed extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      partner: props.partner,
      next: null
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderFeed(){
    let {
      partner
    } = this.state

    if(partner.featured_jobs.results.length === 0){
      return <p className={"text-center pt-5 h4"}>There are currently no jobs to be listed.</p>
    }

    let featuredJobs = Array.from(partner.featured_jobs.results)
    featuredJobs.pop()

    return featuredJobs.map(job => {
      return (
        <JobItem
          job={job}
          partner={partner}
        />
      )
    })
  }

  render() {
    let {

    } = this.state

    let viewMore = window.General.JobsPage

    return (
      <>
        <div className={this.props.className}>
          <div className="section">
            <h4>Job Feed</h4>
            <div className="job-cards" id="jobs">
              {this._renderFeed()}
            </div>
            <div className="view-more" id="view-more-jobs">
              <a
                href={viewMore}
              >
                View More
              </a>
            </div>
          </div>
        </div>
      </>
    )
  }
}

Feed.defaultProps = {
  className: "col-lg-6 col-lg-push-3 middlecol"
}
