export default class Indeed {
  static getIndeedTrackingLink(url, partner){
    return url
  }

  static setImpressionLazyLoader(){
    return <script src="https://prod.statics.indeed.com/tmn-imp/v1/lazyload.min.js" />
  }

  static setIndeedImpressionImage(url){
    return <img className="indeed-tmn-pxl" data-src={url} width={1} height={1} style={{display: 'block', marginBottom:-1}} />
  }
}
