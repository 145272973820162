import React from "react";
import onClickOutside from "react-onclickoutside"

class JobContractFilter extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      contracts: props.contracts,
      selectedContracts: props.selectedContracts || 'all',
      showFilters: false
    }
  }

  handleClickOutside = evt => {
    let {
      showFilters
    } = this.state

    if(showFilters){
      this.setState({showFilters: false})
    }
  };

  componentDidMount() {
    let {
      contracts
    } = this.state

    contracts.unshift({contract: 'All', id: 'all'})
    this.setState({contracts}, () => this._formatting())
  }

  // componentWillReceiveProps(nextProps, nextContext) {
  //   this.setState(nextProps, () => this._formatting())
  // }

  _formatting(){
    let {
      contracts,
      selectedContracts
    } = this.state

    let formattedContracts = contracts.map(contract => {
      if(contract.contract === "All"){
        return {
          name: contract.contract,
          id: contract.id,
          isChecked: contract.hasOwnProperty('isChecked') ? contract.isChecked : true
        }
      }else{
        return {
          name: contract.contract,
          id: contract.id,
          isChecked: contract.hasOwnProperty('isChecked') ? contract.isChecked : false
        }
      }
    })

    this.setState({contracts: formattedContracts})
  }

  _handleChange(jobContract){
    let {
      contracts
    } = this.state

    let id = contracts.findIndex((contract) => {
      return contract.name === jobContract.name
    })

    if(jobContract.name !== 'All'){
      contracts[0].isChecked = false;
      contracts[id].isChecked = !contracts[id].isChecked;
      let empty = this._checkFilter('isChecked',true, contracts);
      if(empty.indexOf(false) < 0){
        contracts[0].isChecked = true;
      }
    }else{
      contracts.map(contract => {
        contract.isChecked = false;
      })
      contracts[0].isChecked = true;
    }
    this.setState({contracts})

    let selected = this._getSelectedContracts(contracts)

    this.props.setFilter(selected)
  }

  _checkFilter(key, value, contracts){
    return contracts.map(contract => {
      return contract[key] !== value
    })
  }

  _getSelectedContracts(contracts){
    let selectedContracts = []
    contracts.map(filter => {
      if(filter.isChecked){
        selectedContracts.push(filter.name)
      }
    })
    return selectedContracts
  }

  _renderContracts(){
    let { contracts } = this.state

    return contracts.map(contract => {
      return (
        <li className="input-checkbox col-sm-12">
          <input
            value={contract.name}
            type="checkbox"
            id={`filter-contract-${contract.name}`}
            name={"filter-contract"}
            checked={contract.isChecked}
            onChange={() => {
              this._handleChange(contract)
            }}
          />
          <label className="mt-0" htmlFor={`filter-contract-${contract.name}`}/>
          <span>{contract.name}</span>
        </li>
      )
    })
  }

  render() {
    let {
      showFilters
    } = this.state

    return (
      <>
        <div className={"col-auto"}>
          <button
            style={{padding: '0 25px', borderRadius: 50, backgroundColor: '#f1f1f1'}}
            className={"c-btn btn type--uppercase"}
            onClick={e => {
              e.preventDefault()
              this.setState({showFilters: !showFilters})
            }}
          >
            Contract
          </button>
          {
            showFilters &&
            <div
              className="feature feature-1 boxed boxed--border"
              style={{position: 'absolute', zIndex: 99, marginTop: 10, width: 200}}
            >
              <ul className="checkboxes">
                { this._renderContracts() }
              </ul>
            </div>
          }
        </div>
      </>
    )
  }
}

export default onClickOutside(JobContractFilter)