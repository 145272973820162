import React from "react";
import renderHTML from 'react-render-html';
import AsyncStorage from "../../../utils/AsyncStorage";

export default class CookiePolicyPopUp extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      partner: props.partner
    }
  }

  componentDidMount() {
    AsyncStorage.getItem('cookiePolicy').then(cookie => {
      if(cookie == null){
        this.setState({showCookiePolicy: true})
      }else{
        this.setState({showCookiePolicy: false})
      }
    })
  }

  _closeCookiePolicyPopUp(){
    AsyncStorage.setItem('cookiePolicy', 'seen').then();
    this.setState({showCookiePolicy: false})
  }

  render() {
    let {
      partner
    } = this.state

    if(!this.state.showCookiePolicy){
      return null
    }

    return (
      <>
        <div className="cookie-policy-popup">
          <div className="cookie-policy-body">
            <p className="cookie-policy-text">
              {renderHTML(partner.settings.cookie)}
            </p>
            <button
              id="frontHeadCloseButton"
              type="button"
              style={{paddingLeft: '3.48214286em', paddingRight: '3.48214286em', marginTop: 15}}
              className="btn btn--lg btn--primary type--uppercase"
              onClick={() => {
                this._closeCookiePolicyPopUp()
              }}
            >
              CLOSE
            </button>
          </div>
        </div>
      </>
    )
  }
}