import React from "react";
import renderHTML from 'react-render-html';
import SquareLogo from "../common/SquareLogo";
import Indeed from "../../../utils/Indeed";

export default class JobItem extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      job: props.job,
      partner: props.partner,
      jobLink: props.jobLink,
      className: props.className,
      page: props.page
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState({className: nextProps.className})
  }

  render() {
    let {
      job,
      partner,
      jobLink,
      className,
      page
    } = this.state

    let isItFromCompanyJobPage = false;
    if(page === "company-job"){
      isItFromCompanyJobPage = true;
    }

    return (
      <>
        <img className="amply-pxl" data-src={`${window.Api.PixelTracker}?job_id=${job.id}&source=${partner.slug}&url=${encodeURIComponent(window.location.href)}`} width="0" height="0" style={{display: 'block', marginBottom:-1}} />
        {
          window.General.IndeedImpressionTracking ?
            <img className="indeed-tmn-pxl" data-src={`${job.impression_url}`} width="0" height="0" style={{display: 'block', marginBottom:-1}}/>
          :
            ''
        }
        { isItFromCompanyJobPage &&

          <a
            href={jobLink}
            onClick={e => {
              e.preventDefault()
              this.props.onClick(job)
            }}
            className={"item " + className}
          >
            <h3 className="title">{job.title}</h3>
            <span>{job.location.city || job.location.state || job.location.country}</span>
            <span>{job.company.name}</span>
            {
              job.salary_disclosed &&
              <>
                <span>{job.salary}</span>
              </>
            }
          </a>

        }

        { !isItFromCompanyJobPage &&

          <a
            className={"job-list-item"}
            style={{textDecoration: 'none'}}
            href={jobLink}
            onClick={e => {
              e.preventDefault()
              this.props.onClick(job)
            }}
          >
            <div
              className={"row boxed boxed--border " + className}
              style={{paddingTop: '1.5em', paddingBottom: '1.3em', minHeight: '106px'}}
            >
              <SquareLogo
                job={job}
              />
              <div style={{paddingLeft: 5}} className="col-md-9">
                <div className="job-name">
                  <h4 style={{fontSize:'1em'}}>{job.title}</h4>
                </div>
                <div className="desktop-view">
                  <span style={{color: 'blue', overflowWrap: 'break-word', fontWeight: 'normal'}}>{job.company.name}</span><br/>
                  <span
                    style={{color: 'grey', overflowWrap: 'break-word', fontWeight: 'normal'}}
                  >
                    {job.location.city || job.location.state || job.location.country}
                  </span>
                  {
                    job.salary_disclosed &&
                    <>
                      <br/>
                      <span
                        style={{color: 'grey', overflowWrap: 'break-word', fontWeight: 'normal'}}
                      >
                        {job.salary}
                      </span>
                    </>
                  }
                </div>
              </div>
            </div>
          </a>

        }

      </>
    )
  }
}
