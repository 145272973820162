import React from "react";

import JobItem from "./JobItem";
import Backend from "../../../utils/Backend";
import FetchHelper from "../../../utils/FetchHelper";
import {Link} from "react-router-dom";
import {DebounceInput} from 'react-debounce-input';

export default class Feed extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      channel: props.channel,
      partner: props.partner,
      pageSize: props.pageSize,
      jobs: props.jobs,
      next: null
    }
  }

  componentDidMount() {
    // this._loadFeed()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _loadFeed(){
    let {
      channel,
      partner,
      pageSize,
      searchTerm
    } = this.state

    Backend.getChannelFeed(channel, partner, searchTerm, pageSize).then(response => {
      this.setState({
        jobs: response.results,
        next: response.next
      })
    })
  }

  _handleLoadMore(){
    let {
      jobs,
      next
    } = this.state

    FetchHelper.get(next).then(response => {
      jobs.push.apply(jobs, response.results)
      this.setState({
        jobs,
        next: response.next
      })
    })
  }

  _renderFeed(){
    let {
      jobs,
      partner
    } = this.state

    if(jobs.length === 0){
      return <p className={"text-center pt-5 h4"}>There are currently no jobs to be listed.</p>
    }

    return jobs.map(job => {
      return (
        <JobItem
          job={job}
          partner={partner}
        />
      )
    })
  }

  render() {
    let {
      next,
      channel,
      partner
    } = this.state

    // let viewMore = window.General.JobsPage
    let loadMore = window.General.CompanyJobsPage.replace("[company]", channel)

    return (
      <>
        <div className={this.props.className}>
          <div className="section">
            {
              this.props.showTitle &&
              <h4>Job Feed</h4>
            }
            {
              !this.props.search &&
              <DebounceInput
                type="text"
                placeholder="Search..."
                debounceTimeout={300}
                onChange={e => {
                  let searchTerm = e.target.value
                  this.setState({searchTerm}, () => this._loadFeed())
                }}
              />
            }
            <div className="job-cards" id="jobs">
              {this._renderFeed()}
            </div>
            {
              this.props.hasMore &&
              <div className="view-more" id="view-more-jobs">
                <a
                  href={loadMore}
                >
                  View More
                </a>
              </div>
            }

          </div>
        </div>
      </>
    )
  }
}

Feed.defaultProps = {
  search: false,
  pageSize: 5,
  showTitle: true,
  className: "col-lg-6 col-lg-push-3 middlecol",
  hasMore: false
}
