import React from "react";
import renderHTML from 'react-render-html';
import General from "../../../utils/General";
import Backend from "../../../utils/Backend";
import moment from "moment";

export default class OrganizationSchema extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      company: props.company,
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _getOrganizationSchema(company){
    let description = company.description?.replace(/(\r\n|\n|\r)/gm, "<br/>").replace(/(<([^>]+)>)/ig,"").replace(/\\([\s\S])|(")/g, "\\$1$2");
    let companyPage = window.General.CompanyPage.replace("[company]", company.slug)
    let logo = company.image_logo ? company.image_logo : 'https://dy793rr2xtptx.cloudfront.net/images2/topic/new/jobbio-logo-1559215894139.gif'

    return {
      "@context" : "http://schema.org/",
      "@type" : "Organization",
      "name" : company.name,
      "description" : description,
      "url": companyPage,
      "logo":{
        "contentUrl": logo,
        "description": company.name,
        "@type": "ImageObject"
      },
      "address" : {
        "@type" : "PostalAddress",
        "streetAddress" : company.location.address,
        "addressLocality" : company.location.city,
        "addressRegion" : company.location.state,
        "postalCode" : company.location.post_code,
        "addressCountry": company.location.country_short
      }
    }
  }

  render() {
    let {
      company,
    } = this.state

    return (
      <>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{__html: JSON.stringify(this._getOrganizationSchema(company))}}
        />
      </>
    )
  }
}
