import React from "react";
import $ from 'jquery';
import SearchBar from "../common/SearchBar";

export default class Banner extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      filters: props.filters,
      searchTerm: props.searchTerm,
      location: props.location,
      page: props.page,
      partner: props.partner,
      selectedCategory: props.selectedCategory
    }
  }

  componentDidMount() {

    // starts : search bar fixed
    $(window).scroll(function() {    
      var scroll = $(window).scrollTop();
      if (scroll > 366) {
        $('.comp-search-banner').addClass('pos-fixed');
      } else {
        $('.comp-search-banner').removeClass('pos-fixed');
      }
    });
    // end : search bar fixed

  }

  componentWillReceiveProps(nextProps){
    this.setState({
      filters: nextProps.filters,
      searchTerm: nextProps.searchTerm,
      location: nextProps.location,
      page: nextProps.page,
      partner: nextProps.partner,
      selectedCategory: nextProps.selectedCategory
    })
  }

  render() {
    let {
      page,
      filters,
      searchTerm,
      location,
      partner,
      selectedCategory
    } = this.state

    let sectionHeightClassName = (this.props.size==="height-auto") ? " " : this.props.size
    let sectionContainerClassName = (this.props.size==="height-auto") ? " " : " pos-vertical-center "

    return (
      <>
        <section className={"cover text-center comp-search-banner " + sectionHeightClassName}>
          <div className={"container " + sectionContainerClassName}>
            <div className="row">
              <div className="col-md-12">
                <SearchBar
                  filters={filters}
                  searchTerm={searchTerm}
                  location={location}
                  page={page}
                  partner={partner}
                  selectedCategory={selectedCategory}
                />
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

Banner.defaultProps = {
  size: 'height-40'
}