
import React from "react";
import renderHTML from 'react-render-html';

export default class Content extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      article: props.article
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      article
    } = this.state

    if(article == null){
      return null
    }

    let name = `${article.posted_by}`

    return (
      <>
        <section className="switchable switchable--switch">
          <div className="container">

            <div className="row justify-content-between">
              <div className="col-md-12 col-lg-12">
                <h3><i>By { name }</i></h3>
                { renderHTML(article.text) }
              </div>
            </div>

          </div>
        </section>
      </>
    )
  }
}