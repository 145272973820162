import React from "react";
import {Link} from "react-router-dom";
import General from "../../../utils/General";
import Backend from "../../../utils/Backend";
import Image from "../../../utils/Image";

export default class ItemsSection extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      partner: props.partner,
      type: props.type,
      items: props.items
    }
  }

  componentDidMount() {
    // this._getResults()
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderResults(){
    let {
      type,
      items
    } = this.state

    return items.map(item => {
      if(type === "articles"){
        let title = item.title.length > 85 ? item.title.substring(0, 85) + '...' : item.title
        let articleLink = window.General.ArticlePage.replace("[article]", item.slug)
        return (
          <>
            <div className="col-md-4 result">
              <div className="feature feature-1">
                <div className="img">
                  <a
                    href={articleLink}
                    className="bg-image d-block h-v1"
                    data-overlay="3"
                    style ={{ backgroundImage: "url("+Image.getImageUrl(item.image, "company-card")+")" }}
                  />
                </div>
                <div className="feature__body boxed boxed--border">
                  <div className="name">
                    <a href={articleLink}>
                      {title}
                    </a>
                  </div>
                  <div className="location">{item.description}</div>
                  <div className="link">
                    <a href={articleLink}>
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
      let channelLink = window.General.ChannelPage.replace("[channel]", item.slug)
      let imageUrl = item.card ? Image.getImageUrl(item.card, "channel-card") : General.getRandomBannerImage()
      return (
        <div className="col-md-4 col-sm-6">
          <a href={channelLink} className="block">
            <div className="feature feature-7 boxed text-center imagebg" data-overlay="4">
              <div
                className="background-image-holder"
                style={{ background: `url(${imageUrl})`, opacity: 1}}
              >
                <img alt="background" src={imageUrl}/>
              </div>
              <h4 className="pos-vertical-center">{item.name}</h4>
            </div>
          </a>
        </div>
      )
    })
  }

  render() {
    let {
      type,
      items,
      partner
    } = this.state

    if(!items || items.length === 0) return null

    let title = ''
    let link = ''

    if(partner){
      title = type === "articles" ? `${partner.name} Articles` : `${partner.name} Channels`
      link = type === "articles" ? window.General.ArticlesPage : `/channels`
    }else{
      title = this.props.title
      link = this.props.link
    }


    return (
      <>
        <section 
          id='articles-section'
          className="components common items-section"
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <h4>{title}</h4>
              </div>
            </div>

            <div className="row">
              {this._renderResults()}
            </div>

            <div id='more-articles-button' className="row" style={{marginTop:50}}>
              <div className="col-sm-12 text-center">
                <a className="btn btn--lg" href={link}>
                  <span className="btn__text">View All {General.toTitleCase(type)}</span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}
