import React from "react";
import General from "../../../../utils/General";
import Image from "../../../../utils/Image";

export default class Block16 extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      contentBlock: props.contentBlock
    }
  }

  componentDidMount() {
    setTimeout(() => {
      //General.updateImageBackgrounds()
    }, 3000)
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderImage(image){
    let cloudFrontLink = image.includes(".gif") ? window.General.CloudFront : window.General.CloudFrontResizer
    return (
      <div className="background-image-holder">
        <img alt="image" src={Image.getImageUrl(`${cloudFrontLink}images2/topic/${image}`, 16)}/>
      </div>
    )
  }

  _renderVideo(video){
    return (
      <>
        <div className="video-play-icon "/>
        <iframe data-src={`${video}`} allowFullScreen="allowfullscreen"/>
      </>
    )
  }

  _renderBlock(){
    let {
      contentBlock
    } = this.state

    return contentBlock.block_content_entry.map((content, index) => {
      let sectionClassName = index % 2 === 0 ? 'switchable' : '';
      let innerClassName = index % 2 === 0 ? '' : 'flex-row-reverse'
      return (
        <section
          className={`block-16 ${sectionClassName} feature-large switchable--switch`}
          id={`section-${contentBlock.id}`}
          style={{
            backgroundColor: contentBlock.background_color,
            padding: `${contentBlock.padding_top}px ${contentBlock.padding_right}px ${contentBlock.padding_bottom}px ${contentBlock.padding_left}px`,
            margin: `${contentBlock.margin_top}px ${contentBlock.margin_right}px ${contentBlock.margin_bottom}px ${contentBlock.margin_left}px`
          }}
        >
          <div className="container">
            <div className={`row justify-content-between align-items-center ${innerClassName}`}>
              <div className={`col-lg-6 col-sm-12`}>
                <div className="video-cover border--round box-shadow-wide">
                  {content.image && this._renderImage(content.image)}
                  {
                    content.video ?
                      this._renderVideo(content.video) :
                      <iframe allowFullScreen={"allowFullScreen"}/>
                  }
                </div>
              </div>
              <div className={`col-lg-6 col-xl-5`}>
                <div className={"switchable__text"}>
                  <h2 style={{color: contentBlock.text_color}}>{content.title}</h2>
                  <p className="lead" style={{color: contentBlock.tertiary_color}}>{content.text}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      )
    })
  }

  render() {
    return (
      <>
        {this._renderBlock()}
      </>
    )
  }
}