import React from "react";

import CompanyItem from "./CompanyItem";
import LazyLoadingList from "../common/LazyLoadingList";
import CompanyShimmerItem from "./CompanyShimmerItem";
import General from "../../../utils/General";
import ScriptCache from "../../../utils/ScriptCache";

export default class CompanyResults extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      loading: true,
      searchTerm: props.searchTerm,
      countryId: props.countryId,
      stateId: props.stateId,
      partner: props.partner
    }

    this.lazyLoadingList = React.createRef()
  }

  _loadJs(){
    ScriptCache.loadDefaults()
  }

  _renderCompanies(){
    let {
      partner,
      searchTerm,
    } = this.state

    let search = `?search=${searchTerm}&state_ids=${this.state.stateId}&country_ids=${this.state.countryId}&source=${partner.slug}`;

    if(this.state.items.length === 0 && !this.state.loading){
      return <div style={{margin: 'auto'}}><h3>No companies right now, check back soon!</h3></div>
    }

    if(this.state.items.length > 0){
      this._loadJs()
    }

    return (
      <LazyLoadingList
        ref={this.lazyLoadingList}
        endpoint={`${window.Api.Channels}/${partner.slug}/companies${search}`}
        onItemUpdated={items => {
          this.setState({
            items,
            loading: false
          })
        }}
        renderItem={company => {
          return (
            <CompanyItem
              partner={partner}
              company={company}
            />
          )
        }}
        renderInitialLoading={() => {
          return (
            <>
              <CompanyShimmerItem/>
              <CompanyShimmerItem/>
              <CompanyShimmerItem/>
              <CompanyShimmerItem/>
              <CompanyShimmerItem/>
              <CompanyShimmerItem/>
            </>
          )
        }}
      />
    )
  }

  render() {
    let {

    } = this.state

    return (
      <>
        <div className="row components search company-results">
          {this._renderCompanies()}
        </div>
      </>
    )
  }
}