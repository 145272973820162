import React from "react";

import NavigationBar from "./components/common/NavigationBar";
import Footer from "./components/common/Footer";

import Banner from "./components/search/Banner";
import General from "../utils/General";
import Backend from "../utils/Backend";
import ArticleResults from "./components/search/ArticleResults";
import PreLoad from "./components/common/PreLoad";
import PartnerBranding from "./components/common/PartnerBranding";
import ScriptCache from "../utils/ScriptCache";
import MetaTags from "../utils/MetaTags";

export default class SearchJobs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      partner: props.partner,
      filters: null,
      searchTerm: General.getUrlParameter("searchTerm"),
      selectedCategory: General.getUrlParameter("category"),
    }
  }

  componentDidMount() {
    let data = General.getTrafficData(this.state.partner)
    data["search_articles_page"] = true
    Backend.logTraffic(data)
  }

  render() {
    let {
      selectedCategory,
      searchTerm,
      partner,
      filters
    } = this.state

    let shareTitle = `Career Advice | ${partner.name}`;
    let description = `Articles is our career advice hub covering Interview techniques, salary negotiation, 
                       working remotely and much more from ${partner.name}`;
    let url = window.location.href;
    let shareImage = partner.logo;

    return (
      <>
        { MetaTags.render(shareTitle, description, null, url, shareImage) }
        <NavigationBar
          opacity={'bar--absolute '}
          partner={partner}
          headerFixed='true'
        />
        <div className={'main-container full-page '}>
          <Banner
            selectedCategory={selectedCategory}
            searchTerm={searchTerm}
            filters={filters}
            page={"articles"}
            partner={partner}
          />
          <section className="search-tabs">
            <div className="container">
              <ArticleResults
                selectedCategory={selectedCategory}
                searchTerm={searchTerm}
                partner={partner}
              />
            </div>
          </section>
          <Footer
            partner={partner}
          />
        </div>
      </>
    )
  }
}