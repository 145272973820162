import React from "react";
import Map from "./Map";

export default class Block17 extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      partner: props.partner,
      contentBlock: props.contentBlock
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderBlockStyle(){
    let {
      contentBlock
    } = this.state

    return (
      <style
        dangerouslySetInnerHTML={{__html: `
          #section-${contentBlock.id} .ember-cli-g-map {
            height: 1064px;
          }
        `}}
      />
    )
  }

  render() {
    let {
      partner,
      contentBlock
    } = this.state

    if(!window.Api.GoogleMapsKey) return null

    let fullAddress = `${contentBlock.location.address}, ${contentBlock.location.city}, ${contentBlock.location.state}, ${contentBlock.location.country}`;
    let cloudFrontLink = contentBlock.image.includes(".gif") ? window.General.CloudFront : window.General.CloudFrontResizer

    return (
      <>
        {this._renderBlockStyle()}
        <section
          className="block-17 imageblock feature-large space--lg bg--primary"
          id={`section-${contentBlock.id}`}
          style={{
            backgroundColor: contentBlock.background_color,
            padding: `${contentBlock.padding_top}px ${contentBlock.padding_right}px ${contentBlock.padding_bottom}px ${contentBlock.padding_left}px`,
            margin: `${contentBlock.margin_top}px ${contentBlock.margin_right}px ${contentBlock.margin_bottom}px ${contentBlock.margin_left}px`
          }}
        >
          <div className="imageblock__content col-xl-6 col-lg-4 col-12 pos-right">
            <div className="map-container">
              <Map
                map={contentBlock}
                partner={partner}
              />
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-7">
                <img src={`${cloudFrontLink}images2/topic/${contentBlock.image}`}/>
                <h2 style={{color: contentBlock.text_color}}>{contentBlock.title}</h2>
                <h3 style={{color: contentBlock.text_color}}>{fullAddress}</h3>
                <hr className="short" style={{borderColor: '#fff'}}/>
                <p className="lead" style={{color: contentBlock.text_color}}>{contentBlock.text}</p>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}