import React from "react";

export default class Block11 extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      contentBlock: props.contentBlock
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _renderBlockStyle(){

  }

  _renderContent(){
    let {
      contentBlock
    } = this.state

    return contentBlock.block_content_entry.map(content => {
      return (
        <li>
          <div className="testimonial">
            <blockquote>{content.text}</blockquote>
            <h5>{content.title}</h5>
            <span>{content.subtitle}</span>
          </div>
        </li>
      )
    })
  }

  render() {
    let {
      contentBlock
    } = this.state

    return (
      <>
        <section
          className="block-11 text-center"
          id={`section-${contentBlock.id}`}
          style={{
            backgroundColor: contentBlock.background_color,
            padding: `${contentBlock.padding_top}px ${contentBlock.padding_right}px ${contentBlock.padding_bottom}px ${contentBlock.padding_left}px`,
            margin: `${contentBlock.margin_top}px ${contentBlock.margin_right}px ${contentBlock.margin_bottom}px ${contentBlock.margin_left}px`
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-10 col-lg-8">
                <div className="slider" data-paging="true">
                  <ul className="slides">
                    {this._renderContent()}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}