import React from "react";

import LocationInput from "./LocationInput";
import Backend from "../../../utils/Backend";
import AsyncSelect from "./AsyncSelect";

export default class SearchBar extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: props.searchTerm,
      location: props.location,
      page: props.page,
      partner: props.partner,
      selectedCategory: props.selectedCategory,
      categories: []
    }
  }

  componentDidMount() {
    if(this.state.page === "articles"){
      this._loadCategories()
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState(nextProps)
  }

  _loadCategories(){
    Backend.getArticleCategories(this.state.partner.slug).then(categories => {
      this.setState({categories})
    })
  }

  _handleSearch(){
    let {
      selectedCategory,
      searchTerm,
      location,
      page,
    } = this.state

    location = location?.raw || location

    if(page === "jobs"){
      window.location.href = `${window.General.JobsPage}?searchTerm=${searchTerm || ''}&location=${location || ''}${this.props.home ? '&homepage=true' : ''}`
    }else if(page === "companies"){
      window.location.href = `${window.General.CompaniesPage}?searchTerm=${searchTerm || ''}`
    }else if(page === "articles"){
      window.location.href = `${window.General.ArticlesPage}?searchTerm=${searchTerm || ''}&category=${selectedCategory || ''}`
    }else{
      window.location.href = `${page}?searchTerm=${searchTerm || ''}&location=${location || ''}`
    }

  }

  render() {
    let {
      selectedCategory,
      searchTerm,
      categories,
      location,
      page,
    } = this.state

    let searchTermClass = ""

    if(page === "companies"){
      searchTermClass = "col-lg-12"
    }else{
      searchTermClass = "col-lg-7"
    }

    location = location ? {label:location} : undefined

    if(page.includes("/jobs")){
      page = "jobs"
    }

    return (
      <>
        <div className="boxed boxed--lg bg--white text-left" id="search">
          <form className="form--horizontal search">
            <div className="col-lg-9" style={{padding: 0, zIndex: 3}}>
              <div className="equal">
                <div id="search-jobkeyword" className={searchTermClass}>
                  <input
                    type="text"
                    value={searchTerm}
                    placeholder={`Search ${page}...`}
                    onChange={e => {
                      let searchTerm = e.target.value;
                      this.setState({searchTerm})
                    }}
                  />
                </div>
                {
                  page === "articles" &&
                  <div className="col-lg-5">
                    <div className="input-select">
                      <select
                        name="categories"
                        value={selectedCategory}
                        onChange={e => {
                          let selectedCategory = e.target.value;
                          this.setState({selectedCategory})
                        }}
                      >
                        <option value="">All Categories</option>
                        {
                          categories.map(category => {
                            return (
                              <option
                                value={category.name}
                              >
                                {category.name}
                              </option>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>
                }
                {
                  (page === "jobs" || page.includes("/jobs")) &&
                  <div id="search-joblocation" className="col-lg-5">
                    <AsyncSelect
                      endpoint={`${window.Api.Locations}`}
                      placeholder={"Search cities..."}
                      isClearable={true}
                      value={location}
                      onSelected={location => {
                        this.setState({location})
                      }}
                      getOptions={locations => {
                        return locations.map(location => ({
                            value: location.value,
                            label: `${location.value}`,
                            data: location.value
                          })
                        )
                      }}
                    />
                  </div>
                }
              </div>
            </div>
            <div className="col-lg-3">
              <button
                type="submit"
                className="btn btn--primary type--uppercase"
                onClick={e => {
                  e.preventDefault()
                  this._handleSearch()
                }}
              >
                Search
              </button>
            </div>
          </form>
        </div>
      </>
    )
  }
}

SearchBar.defaultProps = {
  home: false
}
